import { useRef, useState } from "react";
import styles from './editable.module.css'
import formatMoney from '../../../../services/utils/formatMoney';
import { updateRealValue, getRealValues, updateVirtualValue, getVirtualValues } from "../../../../services/axios";
import { MoneyInput } from "../../../../components/moneyInput";
import { unformatMoney } from "../../../../services/utils/formatMoney";
import { isoToDateStr } from "../../../../services/utils/iso_to_date_string";
import {
    OutlinedInput,
    InputLabel,
    MenuItem,
    Select,
    FormControl,
    Stack,
    Chip
  } from "@mui/material";

export const EditableRealValue = ({showNotification, setShowEditable, getValues, setRealValues, value, parent_value}) => {
    const [money,setMoney] = useState(formatMoney(value.value))
    const payment_date_ref = useRef()
    const description_ref = useRef()
    const stallments_ref = useRef()

    const cancelUpdate = () => {
        setShowEditable(false)
    }

    const updateValue = async () => {
        
        const data = {
            id: value.id,
            value: unformatMoney(money) || null,
            description: description_ref.current.value || null,
            payment_date: payment_date_ref.current.value ? new Date(payment_date_ref.current.value).toISOString() : null,
            stallment: stallments_ref.current.value || null,
        }

        try {
            if ( window.confirm(`Tem certeza que deseja ATUALIZAR a receita de ID ${value.id} e Valor ${value.value}?`) ){
                const response = await updateRealValue(data)
                showNotification({text: 'Valor Atualizado!', type: 'ok'})
                console.log('atualizou')
                const resp2 = await getRealValues(parent_value.id)
                setRealValues(resp2)
                getValues()
                setShowEditable(null)
            }
        } catch(error) {
            console.log("🚀 ~ error:", error)
            showNotification({text: 'Erro ao atualizar.', type: 'error'})
        }
    }
    return (
        <>
            {Notification}
            <td className={styles.field}>
                <label>Valor: </label>
                <MoneyInput value={money} setValue={setMoney} />
            </td>
            <td className={styles.field}>
                <label>Observações: </label>
                <textarea ref={description_ref} defaultValue={value.description} />
            </td>
            <td className={styles.field}>
                <label>Data de pagamento: </label>
                <input type="date" ref={payment_date_ref} defaultValue={value?.payment_date.split('T')[0]} />
            </td>
            <td className={styles.field}>
                <label>Número da parcela: </label>
                <input ref={stallments_ref} defaultValue={value.stallment} />
            </td>
            <td className={styles.buttonsField}>
                <input onClick={() => setShowEditable(false) } type='button' value='Cancelar'/>
                <input onClick={updateValue} type='button' value='Atualizar'/>
            </td>
        </>
    )
}


export const EditableVirtualValue = ({showNotification, setShowEditable, getValues, value, allTags=[]}) => {
    const [money,setMoney] = useState(formatMoney(value.value))
    const payment_date_ref = useRef()
    const description_ref = useRef()
    const reference_date_ref = useRef()
    const tags_ref = useRef()
    const [paid, setPaid] = useState(value.paid)

    const [selectedTags, setSelectedTags] = useState(value?.tags);

    const updateSelectedTags = (e) => {
        let tagsToAdd = []
        let tagsAdded = {}
        e.forEach((tag) => {
            if (tagsAdded[tag.name])
                tagsAdded[tag.name] += 1
            else
                tagsAdded[tag.name] = 1
        })
        console.log(tagsAdded)
        e.forEach((tag) => {
            if (tagsAdded[tag.name] == 1)
                tagsToAdd.push(tag)
        })
        setSelectedTags(tagsToAdd)
    }

    const cancelUpdate = () => {
        setShowEditable(false)
    }

    const updateValue = async () => {
        
        let tag_ids = []
        selectedTags.forEach((tag) => {
            tag_ids.push(tag.id)
        })
        const data = {
            id: value.id,
            value: unformatMoney(money) || null,
            description: description_ref.current.value || null,
            payment_date: payment_date_ref.current.value ? new Date(payment_date_ref.current.value).toISOString() : null,
            reference_date: reference_date_ref.current.value ? new Date(reference_date_ref.current.value).toISOString() : null,
            tags: tag_ids,
            paid: paid
        }

        try {
            if ( window.confirm(`Tem certeza que deseja ATUALIZAR a receita de ID ${value.id} e Valor ${value.value}?`) ){
                const response = await updateVirtualValue(data)
                showNotification({text: 'Valor Atualizado!', type: 'ok'})
                getValues()
                console.log('atualizou')
                setShowEditable(null)
            }
        } catch(error) {
            console.log("🚀 ~ error:", error)
            showNotification({text: 'Erro ao atualizar.', type: 'error'})
        }
    }

    const togglePaid = () => {
        setPaid(prev=>!prev)
    }

    return (
        <>
            {Notification}
            <td className={styles.field}>
                <label>Valor: </label>
                <MoneyInput value={money} setValue={setMoney} />
            </td>
            <td className={styles.field}>
                <InputLabel className="field">Marcadores</InputLabel>
                    <Select
                        multiple
                        style={{minWidth: '80px', maxWidth: '200px', border: 'white'}}
                        value={selectedTags}
                        onChange={(e) => updateSelectedTags(e.target.value)}
                        input={<OutlinedInput label="Multiple Select" />}
                        renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.map((value) => (
                            <Chip key={value.name} label={value.name} style={{backgroundColor: value.color}}/>
                            ))}
                        </Stack>
                        )}
                    >
                        {allTags?.map((tag) => (
                        <MenuItem key={tag.name} value={tag}>
                            {tag.name}
                        </MenuItem>
                        ))}
                    </Select>
            </td>
            <td className={styles.field}>
                <label>Data de referência: </label>
                <input type='date' ref={reference_date_ref} defaultValue={value.reference_date.split('T')[0]} />
            </td>
            <td className={styles.field}>
                <label>Data de pagamento: </label>
                <input type='date' ref={payment_date_ref} defaultValue={value?.payment_date.split('T')[0]} />
            </td>
            <td className={styles.field}>
                <label>Observações: </label>
                <textarea ref={description_ref} defaultValue={value.description} />
            </td>
            <td className={styles.field}>
                <label>Pago: </label>
                <input onChange={togglePaid} checked={paid} type="checkbox"/>
            </td>
            <td className={styles.buttonsField}>
                <input onClick={() => setShowEditable(false)} type='button' value='Cancelar'/>
                <input onClick={updateValue} type='button' value='Atualizar'/>
            </td>
        </>
    )
}