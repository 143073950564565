import axios from "axios";
import { getTokens } from "./utils/getTokens";
import { doRefreshToken } from "./auth_api";

const BASE_URL = process.env.REACT_APP_API

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            console.log('chamou a fila', prom)
            prom.resolve(token)
        }
    })

    failedQueue = [];
}

export const api = axios.create({
    baseURL: BASE_URL
})


api.interceptors.request.use(
    async config => {
        let auth = getTokens()
        config.headers.Authorization = `Bearer ${auth?.access_token}`;
        return config;
    }, (error) => Promise.reject(error)
)

api.interceptors.response.use(
    response => response,
    async (error) => {
        const prevRequest = error?.config;
        if ((error?.response?.status === 403 || error?.response?.status === 401) && !prevRequest?.sent) {
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                })
            } else {
                prevRequest.sent = true;
                try {
                    isRefreshing = true
                    const { access_token } = await doRefreshToken()
                    isRefreshing = false
                    processQueue(null, access_token)
                } catch (error) {
                    processQueue(error, null)
                    console.log("🚀 ~ error:", error)
                    Promise.reject(error)
                }
                return api(prevRequest)

            }
        }

        return Promise.reject(error)
    }
)

//colaboradores 
export const getAllCollaborators = async () => {
    return (await api.get('/collaborator/all?limit=50')).data
}
export const updateVirtualValue = async (value) => {
    console.log(1,value)
    return (await api.put(`/values/virtual/update`, value)).data
}

export const getCollaborators = async (currentPage) => {

    return (await api.get(`/collaborator/all?limit=100&skip=0`)).data
}

export const getCollaboratorProgressions = async (collab_id) => {
    return (await api.get(`/collaborator/${collab_id}/progression/all`)).data
}

export const createCollaborator = async (newCollab) => {

    return (await api.post(`/collaborator/new`, {
        email: newCollab.email,
        name: newCollab.name,
        basic_rem: newCollab.basic_rem,
        progression_stage: newCollab.progression_stage,
        unity_id: newCollab.unity_id,
        urvs: newCollab.urvs,
        date: newCollab.date
    }))
}

export const deleteCollaborator = async (id) => {
    return (await api.delete(`/collaborator/delete/${id}`)).data
}

export const invalidateCollaborator = async (id) => {
    return (await api.delete(`/collaborator/invalidate/${id}`)).data
}

export const updateCollaborator = async (updatedCollab) => {
    return (await api.put('/collaborator/update', updatedCollab)).data
}

export const updateProgression = async (updateProgression) => {
    return (await api.put('/collaborator/progression', updateProgression)).data
}

export const getCollaboratorById = async (coll_id) => {
    return (await api.get(`/collaborator/id/${coll_id}`)).data.name
}

export const createNewProgression = async (newProgression) => {
    return (await api.post('/collaborator/progression/new', newProgression)).data
}

export const deleteProgression = async (progression_id) => {
    return (await api.delete(`/collaborator/progression/${progression_id}`)).data
}

//equipes
export const getUnityUrv = async (unity_id) => {
    return (await api.get(`/unity/urvs/${unity_id}`)).data
}
export const getUnity = async (unity_id) => {
    return (await api.get(`/unity/id/${unity_id}`)).data.name
}

export const getAllUnities = async () => {
    return (await api.get('/unity/all')).data
}

//clientes
export const getCustomerById = async (customer_id) => {
    return (await api.get(`/customer/id/${customer_id}`)).data.name
}
export const getAllCustomers = async (orderBy = 'name', isReverse = false) => {

    return (await api.get(`/customer/?limit=1000&order_by=${orderBy}&reverse=${isReverse}`)).data
}
export const createCustomer = async ({ name, description }) => {

    return (await api.post(`/customer/new`, { name, description }))
}

export const updateCustomer = async (client) => {

    return (await api.put(`/customer/update`, client))
}

export const getCustomerPendencies = async ({ customer_id }) => {

    return (await api.get(`/customer/${customer_id}/pendencies`)).data
}
export const getCustomerValues = async ({ customer_id }) => {

    return (await api.get(`/customer/${customer_id}/values`)).data
}

export const invalidateCustomer = async (customer_id ) => {

    return (await api.delete(`/customer/delete/${customer_id}`)).data
}

//valores

export const createVirtualValue = async (value) => {
    return api.post('/values/virtual/new', value)
}
export const getVirtualValues = async ({ quarter_id, currentPage, orderBy, isReverse }) => {

    return (await api.get(`/values/virtual/quarter/${quarter_id}?skip=${currentPage * 15}&order_by=${orderBy}&reverse=${isReverse}`)).data
}
export const createRealValue = async (value) => {
    return api.post(`/values/real/new`, value)
}
export const getRealValues = async (id) => {
    return (await api.get(`/values/real/virtual/${id}`)).data
}
export const invalidadeVirtualValue = async (vvalue_id) => {
    return (await api.delete(`/values/virtual/invalidate/${vvalue_id}`))
}

export const updateRealValue = async (value) => {
    return (await api.put(`/values/real`, value)).data
}

export const invalidadeRealValue = async (rvalue_id) => {

    return (await api.delete(`/values/real/invalidate/${rvalue_id}`)).data
}

//descobre qual o trimestre atual

export const findQuarter = async () => {
    return (await api.get('/quarter/')).data[0]
}
export const findQuarterByMonthAndYear = async ({ month, year }) => {
    return (await api.get(`/quarter/find?month=${month}&year=${year}`)).data
}


export const createExpense = async (values) => {
    const { value, description, reference_date, payment_date, name, recurrent, unity_id } = values
    return (await api.post(`/values/expenses/new`, {
        value, description, reference_date, payment_date, name, recurrent, unity_id
    }))
}
export const getAllExpenses = async () => {
    return (await api.get(`/values/expenses/all`)).data
}

export const updateExpense = async ({ id, value, description, reference_date, payment_date, name, recurrent, unity_id }) => {
    return (await api.put(`/values/expenses`, { value, description, reference_date, payment_date, name, recurrent, unity_id, id })).data
}

export const updatePaidExpense = async ({ id, paid }) => {
    return (await api.put(`/values/expenses`, { id, paid })).data
}

export const getRecurrent = async () => {
    return (await api.get(`/values/expenses/recurrent`)).data
}
export const deleteExpense = async (expense_id) => {
    return (await api.delete(`/values/expenses/delete/${expense_id}`)).data
}

//audit

export const exportFile = async ({
    by_quarter,
    quarter_id,
    by_month,
    as_json,
    date,
    end_date
}) => {

    return (await api.get(`/audit/export?by_month=${by_month}&date=${date}`))
}
export const audit = async () => {
    return (await api.get('/audit/gen?by_quarter')).data
}
export const quarterAuditByMonth = async ({ month, year }) => {

    return (await api.get(`/audit/gen?generate=false&month=${month}&year=${year}`)).data
}
export const balanceByYear = async (month1, year1, month2, year2) => {

    return (await api.get(`/metrics/balance/start/${month1}/${year1}/end/${month2}/${year2}`)).data
}
export const getUnityMetrics = async ({ unity_id, by_month = false, currentPage = 0, by_quarter = false, isReverse, date, orderBy, end_date = null }) => {
    const mult = 200
    const response = (await api.get(`/metrics/unity/${unity_id}?limit=${mult}&skip=${currentPage * mult}&by_month=${by_month}&by_quarter=${by_quarter}&order_by=${orderBy}&reverse=${isReverse}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
    return response
}

//pagamentos
export const registerPayment = async ({ data, overwrite }) => {
    console.log(data)
    console.log(overwrite)
    return (await api.post(`/values/payments/register${overwrite}`, data))
}
export const getAllBaseRem = async () => {
    return (await api.get(`/values/payments/collaborator/base/all`)).data
}
export const getAllVarPayments = async () => {
    return (await api.get(`/values/payments/collaborator/variable/all`)).data
}
export const markAsPaid = async (payment_id, isPaid) => {
    return (await api.put(`/values/payments/paid/${payment_id}?paid=${isPaid}`)).data
}

export const getOutstandingPayments = async () => {
    return (await api.get(`/values/payments/outstanding/all`)).data
}

// Virtual Values
export const virtualValuesByDate = async ({ by_month = false, by_quarter = false, currentPage, isReverse, date, orderBy, end_date = null }) => {
    const mult = 1000
    return (await api.get(`/values/virtual?limit=${mult}&skip=${currentPage * mult}&by_month=${by_month}&by_quarter=${by_quarter}&order_by=${orderBy}&reverse=${isReverse}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
}

// Expenses
export const expensesByDate = async ({ by_month = false, currentPage = 0, by_quarter = false, isReverse, date, orderBy, end_date = null }) => {
    const mult = 200
    return (await api.get(`/values/expenses?limit=${mult}&skip=${currentPage * mult}&by_month=${by_month}&by_quarter=${by_quarter}&order_by=${orderBy}&reverse=${isReverse}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
}

//payments
export const paymentsByDate = async ({ by_month = false, currentPage = 0, by_quarter = false, isReverse, date, orderBy, end_date = null }) => {
    const mult = 200
    return (await api.get(`/values/payments?limit=${mult}&skip=${currentPage * mult}&by_month=${by_month}&by_quarter=${by_quarter}&order_by=${orderBy}&reverse=${isReverse}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
}

export const paymentsGenerateVarRem = async ({ date, end_date }) => {
    return (await api.get(`/values/payments/variable/all?by_quarter=${true}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
}

export const paymentsGenerateBaseRem = async ({ date, end_date }) => {
    return (await api.post(`/values/payments/base/all?by_month=${true}&date=${date}${end_date ? `&end_date=${end_date}` : ''}`)).data
}

export const deletePayment = async (payment_id) => {
    return (await api.delete(`/values/payments/delete/${payment_id}`)).data
}

// Tags
export const getTags = async () => {
    return (await api.get(`/tags`)).data
}