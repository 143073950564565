import { useEffect, useState } from 'react';
import Modal from 'react-modal'
import { getCustomerPendencies, getCustomerValues, getTags } from '../../../../services/axios';
import PaymentSection from './paymentSection';
import styles from './index.module.css'
import Button from '../../../../components/button';
import ValuesForm from '../../../valores/valuesForm';
import CloseForm from '../../../../components/closeForm';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: 600,
      height: 500,
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#eee',
      color: '#1d3562',
      border: '3px solid gray'
    },
  };

const ClientModal = ({isModalOpen, client, closeModal, showNotification}) => {

  const [pendencies,setPendencies] = useState()
  const [paidValues,setPaidValues] = useState()
  const [openNewValueForm,setOpenNewValueForm] = useState(false)
  const [allTags, setAllTags] = useState([]);

  const resetFormState = () => {
    setOpenNewValueForm(false)
    getClientValues()
  }

  const getClientValues = async () => {
    const values_response = await getCustomerValues({customer_id: client.id})
    const paid_values = values_response.filter((item) => item.paid === true)
    const pendencies_response = await getCustomerPendencies({customer_id: client.id})
    setPaidValues(paid_values)
    setPendencies(pendencies_response)
}

const getData = async () => {
  const tags = await getTags()
  setAllTags(tags)
}

  useEffect(() => {
    getClientValues()
    getData()
  },[])
return (
        <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="ClientModal"
        ariaHideApp={false}
        >
            <div className={styles.container}>
              <div className={styles.topContainer}>
                <div>
                  <h3 className={styles.title}>Cliente: {client.name}</h3>
                  <p className={styles.description}>{client.description?`Descrição: ${client.description}`:'Sem descrição'}</p>
                </div>
                <div>
                  <Button onclick={() => setOpenNewValueForm(true)} title='Nova receita'/>
                </div>
                {openNewValueForm && (
                <>
                  <div className={styles.formContainer}>
                    <ValuesForm getVValues={resetFormState} defaultClient={client} allTags={allTags}/>
                  </div>
                  <CloseForm onclick={() => setOpenNewValueForm(false)} />
                </>
                )}
              </div>

              {pendencies?.length > 0 && (
                <PaymentSection getClientValues={getClientValues} isPendency title='Pendências' values={pendencies}/>
              )}
              {paidValues?.length > 0 && (
                <PaymentSection paid={true} showNotification={showNotification} title='Valores Pagos' values={paidValues}/>
              )}

            </div>
      </Modal>
    )
}

export default ClientModal