import { useRef, useState } from 'react'
import styles from './editable.module.css'
import { updateExpense } from '../../../../../services/axios'
import { EditableComponent } from './input'
import { MoneyInput, formatMoney } from '../../../../../components/moneyInput'
import { isoToDefaultInputValue } from '../../../../../services/utils/iso_to_date_string'
import Button from '../../../../../components/button'
import { useNotification } from '../../../../../components/useNotification'
import { unformatMoney } from '../../../../../services/utils/formatMoney'

const Editable = ({getExpensesByDate, unities, row, setShowEditable}) => {
    const {Notification, showNotification} = useNotification()
    const name_ref = useRef()
    const description_ref = useRef()
    const reference_date__ref = useRef()
    const payment_date_ref = useRef()
    const recurrent_ref = useRef()
    const unity_id_ref = useRef()
    const [money,setMoney] = useState(formatMoney(row.value*100))

    const handleEditExpense = async () => {
        const reference_date = reference_date__ref.current?.value?
                new Date(reference_date__ref.current?.value?.replaceAll('-', '/')).toISOString()
                : null
        const payment_date = payment_date_ref.current.value?
                new Date(payment_date_ref.current.value.replaceAll('-', '/')).toISOString()
                : null

        const value = unformatMoney(money)
        const description =  description_ref.current.value
        const name = name_ref.current.value
        const recurrent = recurrent_ref.current.checked
        const unity_id = unity_id_ref.current.value
        const id = row.id
        
        try {
            const response = await updateExpense({
                value,
                description,
                name,
                recurrent,
                unity_id,
                payment_date,
                reference_date,
                id
            })
            if (response.id!==null){
                showNotification({text: 'Despesa atualizada!', type: 'ok'})
                getExpensesByDate()
                return
            }
        } catch (error) {
            showNotification({text: 'Erro ao editar despesa.', type: 'error'})
            Promise.reject(error)
        }
        
    }

    return (
        <>
        {Notification}
            <tr className={styles.container}>
                <td>
                    <EditableComponent.Container>
                        <EditableComponent.Label label='Nome:'/>
                        <EditableComponent.Input type='text' defaultValue={row.name} refConst={name_ref}/>
                    </EditableComponent.Container> 

                    <EditableComponent.Container>
                        <EditableComponent.Label label='Observações:'/>
                        <EditableComponent.Input type='text' defaultValue={row.description} refConst={description_ref}/>
                    </EditableComponent.Container> 

                </td>
                <td>
                    <EditableComponent.Container>
                        <EditableComponent.Label label='Valor:' />
                        <MoneyInput value={money} setValue={setMoney}/>                  
                    </EditableComponent.Container>

                    <EditableComponent.Container>
                        <EditableComponent.Label label='Data de pagamento:' />
                    <EditableComponent.Input type='date' refConst={payment_date_ref}  defaultValue={isoToDefaultInputValue(row.payment_date)}/> 
                    </EditableComponent.Container>
                </td>

                <td>
                    <EditableComponent.Container>
                        <EditableComponent.Label label='Data de referência:' />
                        <EditableComponent.Input type='date' refConst={reference_date__ref} defaultValue={isoToDefaultInputValue(row.reference_date)}/> 
                    </EditableComponent.Container>

                    <EditableComponent.Container>
                        <EditableComponent.Label label='Recorrente?' />
                        <EditableComponent.Checkbox refConst={recurrent_ref} value={row.recurrent}/>
                    </EditableComponent.Container>
                </td>
                <td>
                    <EditableComponent.Container>
                        <EditableComponent.Label label='Unidade:' />
                        <EditableComponent.Dropdown defaultValue={row.unity_id} refConst={unity_id_ref} array={unities}/>
                    </EditableComponent.Container>
                </td>
                <td className={styles.field}>
                    <Button onclick={() => setShowEditable(false)} title={'Cancelar'} />
                    <br/>
                    <Button externo onclick={handleEditExpense} title={'Atualizar'} />
                </td>
            </tr>
        </>
    )
}

export default Editable