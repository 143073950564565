import { createContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import Cookies from 'js-cookie'
import { auth_api, createSession } from "../services/auth_api"
import { api } from "../services/axios"
import { StorageCookies, removeCookies } from "../services/utils/storage_cookies"
import { useNotification } from "../components/useNotification"

export const AuthContext = createContext()

export const AuthProvider = ({children}) => {

    const {Notification, showNotification } = useNotification()

    const [user, setUser] = useState(null)
    const [auth, setAuth] = useState()
    const navigate = useNavigate()

    const checkUserAndTokenCookies = async () => {
        const cUser = Cookies.get('user')
        const isLoginPage = window.location.href.includes('/login')
		const cRToken = Cookies.get('refresh_token')

		if (cUser && cRToken){
            const user = JSON.parse(cUser)
            const refresh_token = JSON.parse(cRToken)
			setUser(user)
			setAuth({refresh_token})
            if (isLoginPage){navigate('/home')}            
		}else{
            logout()
        }
    }

    useEffect(() => {
       checkUserAndTokenCookies()
	}, [])

    const logout = async () => {
        removeCookies()
        auth_api.defaults.headers.Authorization = null
        api.defaults.headers.Authorization = null
        setUser(null)
        setAuth(null)
        navigate('/')
    }

    const login = async ({credential, user}) => {
        if(credential){
            try {
                const {access_token, refresh_token} = await createSession({token: credential})
                api.defaults.headers.Authorization = `Bearer ${access_token}`
                setUser(user)
                setAuth({access_token, refresh_token})
                StorageCookies({access_token, refresh_token, user})
                navigate('/home')
            } catch (error) {
                showNotification({text: 'Erro ao fazer login.', type: 'error'})
                console.log("🚀 ~ error:", error)
            }
        }else{
            showNotification({text: 'Não foi possível realizar o login.', type: 'error'})
        }
    }
    return (
        <AuthContext.Provider value={{ logged: !!user && !!auth, login, logout, user, auth, setAuth}}>
            {Notification}
            {children}
        </AuthContext.Provider>
    )
}