import './styles.css'
import ChartThisQUarter from './ChartThisQuarter'
import HomeBtns from './HomeBtns'

const icons = [
	{title: 'Cadastrar Receita', iconName: 'attach_money', goTo: '/cadastrarvalor'},
	// {title: 'Cadastrar Pagamento Recebido', iconName: 'payments', goTo: '/cadastrarvalor'},
	{title: 'Cadastrar Despesa', iconName: 'barcode_scanner', goTo: '/despesas'},
	{title: 'Cadastrar Pagamento Realizado', iconName: 'paid', goTo: '/payments'},
]
const Home = () => {
	return (
		<div id='home' className='pageContainer'>
			<div className='btnsContainer'>
			{icons?.map((icon, index) => (
				<HomeBtns key={index} goTo={icon.goTo} title={icon.title} iconName={icon.iconName} />
			))}
			</div>
			<ChartThisQUarter />
		</div>
	)
}
export default Home
