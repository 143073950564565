import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getCollaboratorById, getUnity } from '../../../services/axios'
import AddRealValue from '../addRealValue'
import RealValue from './realValue'
import './styles.css'
import PageTitle from '../../../components/pageTitle'
import Button from '../../../components/button'
import formatMoney from '../../../services/utils/formatMoney'

const VirtualValuePage = () => {
    const location = useLocation()
    const {value} = location.state.data
    const [unityName, setUnityName] = useState()
    const [collaboratorName, setCollaboratorName] = useState()
    const [openForm, setOpenForm] = useState (false)

    const getNamesFromDb = async() => {
        if (!unityName) {
          const unityResponse = await getUnity(value.unity_id)
          setUnityName(unityResponse)
        }
        if (!collaboratorName){
          const collabResponse = await getCollaboratorById(value.collaborator_id)
          setCollaboratorName(collabResponse)
        }
      }

      useEffect(() => {
        getNamesFromDb()
      },[])

    return(
        <div id='virtualValuePage' className='pageContainer'>
            <PageTitle title='Página Detalhada da Receita' />
            <Button externo={true} onclick={() => window.location.href = '/cadastrarvalor'} title='Voltar' />
            <section id='virtualDetailPage' className='section'>
                <div className='field'>
                    <label>Cliente: </label>
                    <span>{value.payer_name}</span>
                </div>
                <div className='field'>
                    <label>Valor: </label>
                    <span>{formatMoney(value.value)}</span>
                </div>
                <div className='field'>
                    <label>Valor pago: </label>
                    <span>{formatMoney(value.value_paid)}</span>
                </div>
                <div className='field'>
                    <label>Descrição: </label>
                    <span>{value.description || 'Sem descrição'}</span>
                </div>
                <div className='field'>
                    <label>Colaborador Responsável: </label>
                    <span>{collaboratorName}</span>
                </div>
                <div className='field'>
                    <label>Unidade: </label>
                    <span>{unityName}</span>
                </div>
                <div className='field'>
                    <label>Data de pagamento: </label>
                    <span>{value.payment_date.slice(0,10).split('-').reverse().join('/')}</span>
                </div>
                <div className='field'>
                    <label>Parcelas:  </label>
                    <span>{value.stallments}</span>
                </div>
                {value.paid?(
                <div className='field'>
                    <label>Pago em:  </label>
                    <span>{value.paid_at.slice(0,10).split('-').reverse().join('/')}</span>
                </div>
                ):
                (
                    <div className='field'>
                        <label>Pagamento ainda não concluído</label>
                    </div>
                )}
                <div className='field btnField'>
                    <Button externo onclick={() => setOpenForm(prev => !prev)} classname='btn' title='Adicionar pagamento recebido'/>
                </div>
                {openForm?(
                    <AddRealValue virtual={value}/>
                ):''}
            </section>
            <PageTitle title='Pagamentos registrados'/>
            <RealValue vvalue_id={value.id} />
        </div>
    )
}

export default VirtualValuePage