import { useLocation } from 'react-router-dom'
import React, { useState } from 'react'
import styles from './index.module.css'
import Button from '../../../components/button'

const ClientPage = () => {
    const location = useLocation()
    const client = location.state.data
    
    return(
        <div className={styles.container}>
          <Button externo={true} onclick={() => window.location.href = '/clientes'} title='Voltar' />
          <div className={styles.centerContainer}>
              <h3 className={styles.title}>{client?.name}</h3>
              <span className={styles.description}>{client?.description}</span>
          </div>
        </div>
    )
}

export default ClientPage