
export function unformatMoney(string) {
    return string.replace(/[^\d]/g, '');
}

export function formatToMoney(value) {
    return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2,
    });
}


//revisar essa função pra ver onde está sendo usada, pra substituir pela de cima ↑ (formatToMoney)
export default function formatMoney(number) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });

    return formatter.format(number);
}