import React, { useEffect } from "react";
import { formatToMoney } from "../../services/utils/formatMoney";

export function formatMoney(value){
  if (!value) return 'R$ 0,00';

  const numericValue = parseFloat(value) / 100;
  
  return formatToMoney(numericValue)

};

export function MoneyInput({ disabled = false, value, setValue }) {
  const handleChangeMoney = (event) => {
    const unformattedValue = event.target.value.replace(/[^\d]/g, '');
    const formattedValue = formatMoney(unformattedValue);
    setValue(formattedValue);
  };

  // const formatMoney = (value) => {
  //   if (!value) return 'R$ 0,00';

  //   const numericValue = parseFloat(value) / 100;
    
  //   return formatToMoney(numericValue)

  // };

  return (
    <input
      disabled={disabled}
      type="text"
      value={value}
      onChange={handleChangeMoney}
      placeholder="R$ 0,00"
    />
  );
}