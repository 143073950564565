import { useRef, useState } from 'react';
import Modal from 'react-modal';
import { useNotification } from '../../../components/useNotification';
import { MoneyInput, formatMoney } from '../../../components/moneyInput';
import { formatToMoney, unformatMoney } from '../../../services/utils/formatMoney';
import Button from '../../../components/button';
import { isoToDateStr, isoToDefaultInputValue } from '../../../services/utils/iso_to_date_string';
import { deleteProgression, updateProgression } from '../../../services/axios';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: 600,
        height: 500,
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#1d3562',
        color: 'White'
    },
};

const ProgressionModal = ({updateProgressionTable, progression, unities, modalIsOpen, setIsOpen}) => {
    const { Notification, showNotification } = useNotification()

    const [money, setMoney] = useState(formatToMoney(progression.basic_rem))
    const urvsRef = useRef()
    const dateRef = useRef(progression.date)
    const endDateRef = useRef()
    const unityRef = useRef(progression.unity_id)
    const levelRef = useRef()

    const [showDeleteForm, setShowDeleteForm] = useState (false)
    const [showEditForm, setShowEditForm] = useState (false)
    const deleteRef = useRef()
    const editRef = useRef()

    const toggleDeleteForm = (e) => {
        e.preventDefault()
        setShowEditForm(false)
        setShowDeleteForm((prev) => !prev)
    }

    const toggleEditForm = (e) => {
        e.preventDefault()
        setShowDeleteForm(false)
        const inputs = document.querySelectorAll('.editableInput')
        setShowEditForm((prev)=> !prev)
        inputs.forEach((input) => input.removeAttribute('disabled'))
    }

    const editUser = async (e) => {
        e.preventDefault()
        const date = new Date(dateRef.current.value.split('-').join('/')) 
        if(editRef.current.value.toUpperCase() === 'EDITAR'){
          try {
            let newProgression = {
              id: progression.id,
              date: new Date(dateRef.current.value.split('-')),
              basic_rem: unformatMoney(money),
              urvs: urvsRef.current.value,
              unity_id: unityRef.current.value,
              collaborator_id: progression.collaborator_id,
              end_date: endDateRef.current.value ? new Date(endDateRef.current.value.split('-')) : null
            }
            
           const responseProgression= await updateProgression(newProgression)
          //  const responseProgression = await createNewProgression(newProgression)
            await updateProgressionTable(progression.collaborator_id)
            showNotification({text: 'Progressão Editada!', type: 'ok'})
          return
          } catch (error) {
            Promise.reject(error)
            showNotification({text: 'Erro ao editar progressão!', type: 'error'})
            console.log(error)
          }
        }else{
          showNotification({text: 'Palavra digitada não confere.', type: 'warning'})
          return
        }
      }

      const deleteUser = async(e) => {
        e.preventDefault()
  
        if(deleteRef.current.value.toUpperCase() === 'DELETAR'){
         try {
            await deleteProgression(progression.id)
            await updateProgressionTable(progression.collaborator_id)
            showNotification({text: 'Progressão removida!', type: 'ok'})
         } catch (error) {
            console.log("🚀 ~ error:", error)
            showNotification({text: 'Erro ao tentar deletar Progressão.', type: 'error'})
         }
        }else{
            showNotification({text: 'Palavra digitada não confere.', type: 'warning'})
        }
    }
    async function openModal() {
        setIsOpen(true);
    }
      
    function closeModal() {
        setShowDeleteForm(false)
        setShowEditForm(false)
        setIsOpen(false);
    }

    return(
        <>
        {Notification}
          <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Collaborator"
          ariaHideApp={false}
          >
            <form id='modal'>
              <div className='modalField'>
                <label className='label'>Remuneração: </label>
                <MoneyInput value={money} className={'editableInput'} setValue={setMoney}/>
              </div>
              <div className='modalField'>
                <label className='label'>Urvs: </label>
                <input ref={urvsRef} className='editableInput' name='urvs' type='number' disabled defaultValue={progression.urvs}
                />
              </div>
              <div className='modalField'>
                <label className='label'>Equipe:  </label>
                <select disabled ref={unityRef} className='editableInput' defaultValue={progression.unity_id} >
                    {unities?.map((unity, index) => 
                      <option key={index} value={unity.id}>{unity.name}</option>
                    )}
                </select>
              </div>
              <div className='modalField'>
                <label className='label'>Nível de progressão na carreira: </label>
                <input className='editableInput' name='progression_stage' type='number' disabled 
                defaultValue={progression.progression_stage}
                ref={levelRef}
                />
              </div>
              <div className='modalField'>
                <label className='label'>Data de início: </label>
                <input className='editableInput' name='progression_stage' type='date' disabled 
                defaultValue={isoToDefaultInputValue(progression.date)}
                ref={dateRef}
                />
              </div>
              <div className='modalField'>
                <label className='label'>Data final: </label>
                <input className='editableInput' name='progression_stage' type='date' disabled 
                defaultValue={isoToDefaultInputValue(progression.end_date)}
                ref={endDateRef}
                />
              </div>
              <div className='modifyBtnsContainer'>
                <Button externo={true} classname='modifyBtns' onclick={toggleEditForm} title='Editar Progressão'/>
                <Button externo={true} onclick={toggleDeleteForm} classname='modifyBtns'title='Deletar Progressão'/>
              </div>
              {showEditForm?(
              <div className=''>
                {/* <div className='deleteLabel'>
                  <label>Data da alteração: </label>
                  <input ref={modifyDateRef} type='date' defaultValue={today} />
                </div> */}
                <label className='deleteLabel'>Deseja realmente <span className='highlight'>EDITAR</span> o Colaborador atual no banco de dados?</label><br/>
                <label className='deleteLabel'>Digite <span className='highlight'>EDITAR</span> para prosseguir: </label>
                <input type='text' ref={editRef}></input>
                <Button externo={true} onclick={editUser} title='Confirmar' classname='submitBtn'/>
              </div>
              )
              :''
            }
              {showDeleteForm?(
              <div className=''>
                <label className='deleteLabel'>Deseja realmente <span className='highlight'>DELETAR</span> o Colaborador atual do banco de dados?</label><br/>
                <label className='deleteLabel'>Um colaborador só deve ser deletado se seu cadastro for <span className='highlight'>INDEVIDO</span>!</label><br/>
                <label className='deleteLabel'>Se o colaborador tiver saído do escritório, apenas finalize sua última progressão</label><br/>
                <label className='deleteLabel'>Digite <span className='highlight'>DELETAR</span> para prosseguir: </label>
                <input type='text' ref={deleteRef}></input>
                <Button externo={true} classname='submitBtn' onclick={deleteUser} title='Confirmar'/>
              </div>
              )
              :''
            }
            <div className='closeModalBtn' onClick={closeModal}>Voltar</div>
            </form>
        </Modal>
          </>
      )
}

export default ProgressionModal