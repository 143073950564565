import { useCallback, useContext, useEffect, useState } from 'react'
import styles from './payments.module.css'
import { getOutstandingPayments, paymentsByDate, paymentsGenerateBaseRem, paymentsGenerateVarRem } from '../../services/axios'
import Button from '../../components/button'
import PageTitle from '../../components/pageTitle'
import { DatePickerContext } from '../../contexts/datePickerCtx'
import LoadContainer from '../../components/loading'
import NewPaymentsContainer from './newPaymentsContainer'
import PaymentTable from './table'
import { useNotification } from '../../components/useNotification'
const Payments = () => {
    const {Notification, showNotification} = useNotification()
    const {period, button_ref, formatCurrentDate, handleSubmit, unity } = useContext(DatePickerContext)
    const [payments, setPayments] = useState ([])
    const [outstanding, setOutstanding] = useState ([])
    const [formIsOpen, showForm] = useState(false) 
    const [isLoading, setIsLoading] = useState(false) 
    const [isReverse, setIsReverse] = useState(false) 
    const [orderBy, setOrderBy] = useState('name') 

    const getOutstanding = useCallback(
      async() => {
        const no_filter = await getOutstandingPayments()
        let response = no_filter.unities
        if(unity !== 'todas'){
            response = no_filter.unities.filter((item) => item.id===parseInt(unity))
        }
        setOutstanding(response)
    },
      [unity])

    const getData = async(reset = false) => {
        setIsLoading(true)

        const {date, end_date} = formatCurrentDate()
        if(!date){
            console.log('erro ao processar data. -> ', date)
            if (reset){ showNotification({text: 'Por favor, preencha o campo com a data.', type: 'warning'}) }
            setIsLoading(false)
            return
        }

        const no_filter = await paymentsByDate({
            date: date,
            end_date: end_date,
            by_month: period==='by_month',
            by_quarter: period==='by_quarter',
            isReverse: isReverse,
            orderBy: orderBy,
        })
        let response = no_filter.unities
        if(unity !== 'todas'){
            response = no_filter.unities.filter((item) => item.id===parseInt(unity))
        }
        setPayments(response)
        setIsLoading(false)
        getOutstanding()
    }

    const handleShowForm = () => {
        showForm(prev => !prev)
    }

    const goToCollaborators = () => {
        window.location.href = '/colaboradores'
    }

    const generateVarRem = async () => {
        const {date, end_date} = formatCurrentDate()
        const response = await paymentsGenerateVarRem({date, end_date})
        getData()
    }

    const generateBaseRem = async () => {
        const {date, end_date} = formatCurrentDate()
        const response = await paymentsGenerateBaseRem({date, end_date})
        getData()
    }

    useEffect(()=>{
        setPayments([])
        getData()
        button_ref.current.onclick = () => handleSubmit(getData)
	},[period, unity])

    return(
    <div className={`${styles.page} pageContainer`}>
        {Notification}
        {isLoading && <LoadContainer/>}
        <PageTitle title={'Gerenciador de Pagamentos'}/>
        <div className={styles.tableContainer}>
            <div className={styles.topButtonsContainer}>
                <Button externo title={'Novo Pagamento'} onclick={handleShowForm} />
                <Button externo title={'Colaboradores'} onclick={goToCollaborators} />
                <Button externo title={'Gerar remuneração variável do período'} onclick={generateVarRem} />
                <Button externo title={'Gerar remuneração base do período'} onclick={generateBaseRem} />
            </div>
            {formIsOpen && <NewPaymentsContainer getData={getData}/>}
            <h2>Pagamentos do Período</h2>
            {payments?.map((paymentByUnity) => (
                <PaymentTable getData={getData} paymentByUnity={paymentByUnity}/>
            ))}

            <h2>Todos pagamentos pendentes</h2>
            {outstanding?.map((paymentByUnity) => (
                <PaymentTable getData={getData} paymentByUnity={paymentByUnity}/>
            ))}
        </div>
    </div>
    )
}

export default Payments