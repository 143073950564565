import { useRef, useState } from 'react';
import Modal from 'react-modal';
import { useNotification } from '../../../components/useNotification';
import { MoneyInput, formatMoney } from '../../../components/moneyInput';
import { formatToMoney, unformatMoney } from '../../../services/utils/formatMoney';
import Button from '../../../components/button';
import { isoToDateStr, isoToDefaultInputValue } from '../../../services/utils/iso_to_date_string';
import { createNewProgression, deleteProgression, updateProgression } from '../../../services/axios';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: 600,
        height: 500,
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#1d3562',
        color: 'White'
    },
};

const AddProgressionModal = ({updateProgressionTable, colab, unities, modalIsOpen, setIsOpen}) => {
    const { Notification, showNotification } = useNotification()

    const [money, setMoney] = useState()
    const urvsRef = useRef()
    const dateRef = useRef()
    const endDateRef = useRef()
    const unityRef = useRef()
    const levelRef = useRef()

    const [showDeleteForm, setShowDeleteForm] = useState (false)
    const [showEditForm, setShowEditForm] = useState (false)

    const toggleDeleteForm = (e) => {
        e.preventDefault()
        setShowEditForm(false)
        setShowDeleteForm((prev) => !prev)
    }

    const toggleEditForm = (e) => {
        e.preventDefault()
        setShowDeleteForm(false)
        const inputs = document.querySelectorAll('.editableInput')
        setShowEditForm((prev)=> !prev)
        inputs.forEach((input) => input.removeAttribute('disabled'))
    }

    const addNewProgression = async (e) => {
        e.preventDefault()
        const date = new Date(dateRef.current.value.split('-').join('/')) 
        try {
          let newProgression = {
            date: new Date(dateRef.current.value.split('-')),
            basic_rem: unformatMoney(money),
            urvs: urvsRef.current.value,
            unity_id: unityRef.current.value,
            collaborator_id: colab.id,
            end_date: endDateRef.current.value ? new Date(endDateRef.current.value.split('-')) : null
          }
          
          const responseProgression= await createNewProgression(newProgression)
        //  const responseProgression = await createNewProgression(newProgression)
          await updateProgressionTable(colab.id)
          showNotification({text: 'Progressão Editada!', type: 'ok'})
        return
        } catch (error) {
          Promise.reject(error)
          showNotification({text: 'Erro ao adicionar progressão!', type: 'error'})
          console.log(error)
        }
      }
    async function openModal() {
        setIsOpen(true);
    }
      
    function closeModal() {
        setShowDeleteForm(false)
        setShowEditForm(false)
        setIsOpen(false);
    }

    return(
        <>
        {Notification}
          <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Collaborator"
          ariaHideApp={false}
          >
            <form id='modal'>
              <div className='modalField'>
                <label className='label'>Remuneração: </label>
                <MoneyInput value={money} className={'editableInput'} setValue={setMoney}/>
              </div>
              <div className='modalField'>
                <label className='label'>Urvs: </label>
                <input ref={urvsRef} className='editableInput' name='urvs' type='number'
                />
              </div>
              <div className='modalField'>
                <label className='label'>Equipe:  </label>
                <select ref={unityRef} className='editableInput' defaultValue={colab.progression.unit_id}>
                    {unities?.map((unity, index) => 
                      <option key={index} value={unity.id}>{unity.name}</option>
                    )}
                </select>
              </div>
              <div className='modalField'>
                <label className='label'>Nível de progressão na carreira: </label>
                <input className='editableInput' name='progression_stage' type='number' defaultValue={colab.progression.progression_stage}
                ref={levelRef}
                />
              </div>
              <div className='modalField'>
                <label className='label'>Data de início: </label>
                <input className='editableInput' name='progression_stage' type='date'
                ref={dateRef}
                />
              </div>
              <div className='modalField'>
                <label className='label'>Data final: </label>
                <input className='editableInput' name='progression_stage' type='date'
                ref={endDateRef}
                />
              </div>
              <div className='modifyBtnsContainer'>
                <Button externo={true} onclick={addNewProgression} title='Adicionar nova progressão' />
              </div>
            <div className='closeModalBtn' onClick={closeModal}>Voltar</div>
            </form>
        </Modal>
          </>
      )
}

export default AddProgressionModal