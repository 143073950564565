import axios from 'axios'
import { getTokens } from './utils/getTokens'
import { StorageCookies, removeCookies } from './utils/storage_cookies'

const BASE_URL = 'https://api.schiefler.adv.br'
// const BASE_URL = 'https://api.schiefler.adv.br/financeiro'

export const refresh_api = axios.create({
    baseURL: BASE_URL
})

export const auth_api = axios.create({
    baseURL: BASE_URL
})

export const createSession = async ({ token }) => {
    return (await auth_api.post('/auth/google-login', { token: token, scopes: ["financeiro", "admin", "adv"] })).data
}

export const doRefreshToken = async () => {
    let auth = getTokens()
    console.log('refreshing')
    refresh_api.defaults.headers.Authorization = `Bearer ${auth.refresh_token}`

    try {
        const response = (await refresh_api.post('/auth/refresh')).data
        StorageCookies({ access_token: response.access_token, refresh_token: response.refresh_token })
        return response
    } catch (error) {
        if (error.response?.status === 404 || error.response?.status === 401) {
            removeCookies()
            window.location.href = '/login'
        }
    }
}
