import { useRef } from 'react'
import styles from './editable.module.css'
import { updateCustomer } from '../../../../services/axios'


const EditableClient = ({showNotification, setShowEditable, getClients, client}) => {
    const name_ref = useRef()
    const description_ref = useRef()

    const cancelUpdate = () => {
        setShowEditable(false)
    }

    const updateClient = async () => {

        const data = {
            id: client.id,
            name: name_ref.current.value || null,
            description: description_ref.current.value || null
        }

        try {
            const response = await updateCustomer(data)
            if (response.status === 200){
                showNotification({text: 'Cliente Atualizado!', type: 'ok'})
                console.log('atualizou')
                getClients()
                setShowEditable(false)
            }
            
        } catch (error) {
            console.log("🚀 ~ error:", error)
            showNotification({text: 'Erro ao atualizar.', type: 'error'})
        }
    }

    return(
        <tr className={styles.container}>
            {Notification}
            <td></td>
            <td className={styles.field}>
                <label>Nome: </label>
                <input ref={name_ref} defaultValue={client.name} />
            </td>
            <td className={styles.field}>
                <label>Observações: </label>
                <input ref={description_ref} defaultValue={client.description} />
            </td>
            <td className={styles.buttonsField}>
                <input onClick={() => setShowEditable(false) } type='button' value='Cancelar'/>
                <input onClick={updateClient} type='button' value='Atualizar'/>
            </td>
        </tr>
    )
} 

export default EditableClient