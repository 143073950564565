
import { useEffect, useState } from 'react'
import formatMoney from '../../../services/utils/formatMoney'
import Item from './item'
import styles from '../../payments/table/paymentsTable.module.css'

const ExpensesTable = ({getExpensesByDate, unities, unity}) => {
    // console.log("🚀 ~ unity:", unity)
    const value = unity.expenses
    const [total, setTotal] = useState()

    useEffect(() => {
        let sum = 0
        value?.map((item) => sum +=item.value)
        setTotal(sum)
    }, [value])
    return(
        <>
        <h3>{unity.name}</h3>
        <table className={styles.table}>
            <thead>
                <tr>
                    <th>Tipo</th>
                    <th>Observações</th>
                    <th>Data de referência</th>
                    <th>Valor</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
                <tbody>
                {value?.map((row, index) => (
                        <Item getExpensesByDate={getExpensesByDate} unities={unities} key={index} row={row}/>
                        )
                        )}
                    <tr className='valueRow despesasRow'>
                        <td className='value tdTotal'>
                        TOTAL 
                        </td>
                        <td></td>
                        <td></td>
                        <td className='value tdTotal'>
                        {formatMoney(total)}
                        </td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
    }

export default ExpensesTable