import { useEffect, useState } from "react"
import Button from "../../../components/button"
import PaymentsForm from "../paymentsForm"
import { getAllCollaborators } from "../../../services/axios"

const NewPaymentsContainer = ({getData}) => {
    const [fieldCounter, setFieldCounter] = useState(['field'])
    const [collabs, setCollabs] = useState([])

    const getCollabs = async () => {
        const response = await getAllCollaborators()
        setCollabs(response)
    }

    const handleSubmitAllForms= () => {
        const allbtns = Array.from(document.querySelectorAll('.submitPayments'))
        allbtns.forEach((btn) => btn.click())
    }
    
    useEffect(() => {
        getCollabs()
    }, [])

    return (
        <div className='middleContainer'>
            <div className='whiteBorder'>
                <Button classname='paymentsBtn' onclick={() => setFieldCounter(prev => [...prev,'One More'])} title='Adicionar mais pagamentos' />
            </div>   
            {fieldCounter.map((field, index) => <PaymentsForm index={index+1} key={index} collabs={collabs} getData={getData}/>)}
            <Button externo={true} classname='paymentsBtn' onclick={handleSubmitAllForms} title='Enviar Todos Pagamentos'/>
        </div>
    )
}

export default NewPaymentsContainer