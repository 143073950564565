import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'

import '../styles.css'

const BarChart = ({title, data}) => {

	return (
		<div className='biggerChart'>
			<h2 className='title'>{title}</h2>
			<Bar data={data}/>
		</div>
	)
}

export default BarChart
