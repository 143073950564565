
const now = new Date()
export const year = now.getFullYear();
export const month = `${now.getMonth() + 1}`.padStart(2, '0');
export const day = `${now.getDate()}`.padStart(2, '0');
export const today = `${year}-${month}-${day}`

export const formatDate = (date) => {
    const now = new Date(date)
    const year = now.getFullYear();
    const month = `${now.getMonth() + 1}`.padStart(2, '0');
    const day = `${now.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`
} 