import Collab from './collab'
import './styles.css'

const PaymentsTable = ({unity_payments}) => {
    return(
        <>  
            <table id='paymentsTable'>
                <thead>
                    <tr>
                        <td className='topTitles mainTitle'>Pagamentos</td>
                    </tr>
                </thead>
                <tbody className=''>
                {unity_payments.collaborators?.map((collab, index) => (
                    <Collab key={index} collab={collab}/>
                ))}
                </tbody>
            </table>
        </>
    )
}

export default PaymentsTable