import { useEffect, useState } from 'react'
import ColabTable from './colabTable/index.jsx'
import './styles.css'
import RegisterForm from './registerForm/index.jsx'   
import { getCollaborators } from '../../services/axios'
import PageTitle from '../../components/pageTitle/index.jsx'
import Button from '../../components/button/index.jsx'
import LoadContainer from '../../components/loading/index.jsx'


const Colaboradores = () => {
    const [colabs, setColabs] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [registerIsVisible, setRegisterIsVisible] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [blur, setBlur] = useState(true)
    
    const showRegister = () => {
        if (registerIsVisible) {
            setRegisterIsVisible(false)
            return
        }
        setRegisterIsVisible(true)
    }

    const getCollabs = async () => {
        setIsLoading(true)
        const data = await getCollaborators(currentPage)
        if (!data){
            setIsLoading(false)
            return
        }
        setColabs(data)
        setIsLoading(false)
    }

    useEffect(() => {
        getCollabs()
    },[currentPage])

    // useEffect(()=> {
    //     const observer = new IntersectionObserver ((entries) => {
    //         if (entries.some((entry) => entry.isIntersecting)) {
    //             console.log('showing..')
    //             setCurrentPage((prev) => prev + 1)
    //         }
    //     })

    //     observer.observe(document.querySelector('#ward'))
    //     return () => observer.disconnect()
    // }, [])

    return (
        <div id="colaboradores" className="pageContainer">
            {isLoading && <LoadContainer/>}
            <PageTitle title={'Colaboradores'}/>
            <div className='mainButtonsContainer'>
                <Button externo onclick={showRegister} title='Novo Colaborador' />
                <Button onclick={() => window.location.href = '/payments'} title='Voltar' />
                <Button onclick={() => setBlur(!blur)} title={<span className="material-symbols-outlined">{blur ? "visibility_off" : "visibility"}</span>} />
            </div>
            <br/>
            {registerIsVisible?
            <RegisterForm getCollabs={getCollabs} colabs={colabs} setColabs={setColabs} />
            :<></>}
            <div className='valuesContainer'>
            {/* <div className='filterContainer'>
                <label className='filterLabel'>Pesquisar colaboradores: </label>
                <input className='filterInput' type='text'/>
            </div> */}
            
                {colabs?
                <ColabTable blur={blur} getCollabs={getCollabs} values={colabs} setValues={setColabs} />:''
                }
            </div>
        </div>
    )
}

export default Colaboradores

