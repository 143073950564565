import BarChart from '../../../../components/charts/barChart'
import { useEffect, useState } from 'react'
import './styles.css'
const ChartTotal = ({data}) => {
    const {result, real, virtual, title, pendency, expenses} = data
	const [barValues, setBarValues] = useState()
	const [chartName, setChartName] = useState()

	// const expenses = []

	async function doAudit () {
		setChartName(`${title}`)
		const newValues = {
			labels: ['Total'],
			datasets: [
				{
					label: 'Resultado',
					data: [result],
					backgroundColor: ['rgba(0, 255,0, 0.5)'],
					borderColor: ['rgb(0, 255, 55)'],
					borderWidth: 1,
				},
				{
					label: 'Receita',
					data: [virtual],
					backgroundColor: ['rgba(0, 0, 255, 0.5)'],
					borderColor: ['rgb(0, 0, 255)'],
					borderWidth: 1,
				},
				{
					label: 'Pagamentos recebidos',
					data: [real],
					backgroundColor: ['rgba(0, 220, 0, 0.2)'],
					borderColor: ['rgb(0, 220, 0)'],
					borderWidth: 1,
				},
				{
					label: 'Pendencias',
					data: [pendency],
					backgroundColor: ['rgba(220, 0, 220, 0.5)'],
					borderColor: ['rgb(220, 0, 220)'],
					borderWidth: 1,
				},
				{
					label: 'Despesas',
					data: [expenses],
					backgroundColor: ['rgba(220, 0, 0, 0.5)'],
					borderColor: ['rgb(220, 0, 0)'],
					borderWidth: 1,
				},
			],
		}
		setBarValues(newValues)
	}

	useEffect(() => {
		doAudit()
	},[])

	return (
    <div className='metricsChartContainer'>
            {barValues?<BarChart title={chartName} data={barValues}/>:''}
    </div>
	)
}

export default ChartTotal
