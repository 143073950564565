import logo from '../../images/logo_branca.png'
import './styles.css'

const Header = () => {

	return (
		<div id='header'>
			<img className='logo' src={logo} />
			<span className='minorText'>FINANCEIRO</span>
		</div>
	)
}

export default Header
