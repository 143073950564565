import Header from './components/header'
import TopBar from './components/topbar'

import Home from './pages/home'
import Metricas from './pages/metricas'
import Despesas from './pages/despesas'
import Valores from './pages/valores'
import Colaboradores from './pages/colaboradores'
import VirtualValuePage from './pages/valores/virtualvaluepage'
import Payments from './pages/payments'
import Clientes from './pages/clientes'
import ClientPage from './pages/clientes/expandedClientPage'
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate
} from 'react-router-dom'
import LoginPage from './pages/login'
import { AuthContext, AuthProvider} from './contexts/auth'
import { DatePickerProvider } from './contexts/datePickerCtx'
import { useContext } from 'react'
import { GlobalDatePicker } from './components/globalDatePicker'

const AppRoutes = () => {

	const Private = ({children}) => {
		const {logout, logged} = useContext(AuthContext)
			return logged?(<>
						<div className='topContent'>
							<Header />
							<TopBar />
						</div>
						<GlobalDatePicker/>
						{children}
					</>):null
	}

	return (
		<Router>
			<AuthProvider>
				<DatePickerProvider>
					<Routes>
						<Route exact path='/' element={ <Navigate to="/login" /> }/>
						<Route exact path='/login' element={<LoginPage/>}/>
						<Route exact path='/home' element={<Navigate to="/clientes" />} />
						<Route exact path='metricas' element={<Private><Metricas /></Private>}/>
						<Route exact path='despesas' element={<Private><Despesas /></Private>}/>
						<Route exact path='cadastrarvalor' element={<Private><Valores/></Private>}/>
						<Route exact path='colaboradores' element={<Private><Colaboradores/></Private>}/>
						<Route exact path='virtualvaluepage' element={<Private><VirtualValuePage/></Private>}/>
						<Route exact path='payments' element={<Private><Payments/></Private>}/>
						<Route exact path='clientes' element={<Private><Clientes/></Private>}/>
						<Route exact path='clientpage' element={<Private><ClientPage/></Private>}/>
					</Routes>
				</DatePickerProvider>
			</AuthProvider>
		</Router>
	)
}

export default AppRoutes
