import { useEffect, useRef, useState } from "react"
import { today } from "../../../components/today"
import { createCollaborator, getAllUnities } from "../../../services/axios"

import './styles.css'
import Button from "../../../components/button"
import { useNotification } from "../../../components/useNotification"

const formPattern = {
    name: '', email: '', unity_id: '',  progression_stage: '', basic_rem: '', urvs: ''
}

const RegisterForm = ({getCollabs, colabs, setColabs}) => {
    const { Notification, showNotification } = useNotification()
    const [values, setValues] = useState(formPattern)
    const [unities, setUnities] = useState ([])
    const dateRef = useRef()

    useEffect(() => {
        (async() => {
            const unitiesData = await getAllUnities()
            setUnities(unitiesData)
        })()
    },[])

    const handleSubmitValues = async (e) => {
        const date = new Date(dateRef.current.value.replace(/-/g, '\/'))
        e.preventDefault()
        document.querySelector('.btnCadastrar').disabled = true
            let newCollab = values
            newCollab.date = date
            newCollab.basic_rem = newCollab.basic_rem *100
            newCollab.progression_stage = newCollab.progression_stage == '' ? null : newCollab.progression_stage
            newCollab.urvs = newCollab.urvs == '' ? null : newCollab.urvs
            const data = await createCollaborator(newCollab)
            if (data?.status ===200){
                showNotification({text:'Colaborador cadastrado!', type: 'ok'})
                setValues(formPattern)
                getCollabs()
            } else{
                showNotification({text:'Erro ao cadastrar colaborador', type: 'error'})
            }
            document.querySelector('.btnCadastrar').disabled = false
    }

    return(
        <form onSubmit={handleSubmitValues} id='containerCadastrar' className='containerCadastrar'>
            {Notification}
            <div className='allFieldsCadastrar'>
                <div className="field">
                    <label>Nome do colaborador: </label>
                    <input required type='text' className='input' 
                    value={values.name} onChange={(e) => {
                        const {...oldValues} = values
                        oldValues.name = e.target.value
                        setValues(oldValues)
                    }} 
                    placeholder='Nome do Colaborador'></input>
                </div>
                <div className="field">
                    <label>Email do Colaborador: </label>
                    <input required type='email' className='input' 
                    value={values.email} onChange={(e) => {
                        const {...oldValues} = values
                        oldValues.email = e.target.value
                        setValues(oldValues)
                    }} 
                    placeholder='Email do Colaborador'></input>
                </div>
                <div className="field">
                    <label>Progressão: </label>
                    <input required type='number' className='input' 
                    value={values.progression_stage} onChange={(e) => {
                        const {...oldValues} = values
                        oldValues.progression_stage = e.target.value
                        setValues(oldValues)
                    }} 
                    placeholder='Progressão'></input>
                </div>
                <div className="field">
                    <label>Unidade: </label>
                    <select className='select'
                            onChange={(e) => {
                                const {...oldValues} = values
                                oldValues.unity_id = parseInt(e.target.value)
                                setValues(oldValues)
                            }} 
                            name="collabSelect" id="collabSelect">
                                    <option value='none' selected disabled hidden>Setor responsável</option>
                                    {unities?.map((unity,index) => 
                                    <option key={index} value={unity.id}>{unity.name}</option>
                                    )}
                            </select>
                </div>
                <div className="field">
                    <label>Remuneração Base</label>
                    <input required type='number' className='input'
                    value={values.basic_rem} onChange={(e) => {
                        const {...oldValues} = values
                        oldValues.basic_rem = e.target.value
                        setValues(oldValues)
                    }} 
                    placeholder='Remuneração Básica'></input>
                </div>
                <div className="field">
                    <label>URVs: </label>
                    <input required type='number' className='input' 
                    value={values.urvs} onChange={(e) => {
                        const {...oldValues} = values
                        oldValues.urvs = e.target.value
                        setValues(oldValues)
                    }}
                    placeholder='urvs'></input>
                </div>
                <div className="field">
                    <label>Data de entrada no escritório: </label>
                    <input ref={dateRef} required type="date" />
                </div>

            </div>
                    <Button externo title='Cadastrar Novo Colaborador' onclick={handleSubmitValues} classname='btnCadastrar'/>
        </form>
    )
}

export default RegisterForm