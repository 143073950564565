import { useState } from 'react'
import { today } from '../../../components/today'
import { createRealValue } from '../../../services/axios'
import './styles.css'
import Button from '../../../components/button'
import { MoneyInput } from '../../../components/moneyInput'
import formatMoney, { unformatMoney } from '../../../services/utils/formatMoney'
import { useNotification } from '../../../components/useNotification'

const AddRealValue = ({getData, setOpenPaymentForm, getClientValues, virtual}) => {
    const {Notification, showNotification } = useNotification()
    const {stallments, quarter_id, payer_id, unity_id, collaborator_id, id} = virtual
    const [formValue,setFormValue] = useState({value: '', description: '', date: today})
    const [money, setMoney] = useState('')
    const handleSubmit = async (e) => { 
        e.preventDefault()
        const value = unformatMoney(money)
        const description = formValue.description
        const payment_date = new Date (formValue.date)

        if (value ===''){
            showNotification({text: 'Preencha o valor.', type: 'warning'}) 
            return
        }
        if (virtual.value*0.3 >= value/100){
           if (!window.confirm(`O valor digitado ${formatMoney(value/100)} é muito menor que o Virtual ${formatMoney(virtual.value)}. Tem certeza que está correto e deseja prosseguir?`)){
            showNotification({text: 'Operação cancelada.', type: 'warning'}) 
            return
            }
        }else if ((virtual.value - virtual.value_paid) < value/100){
           if (!window.confirm(`O valor digitado ${formatMoney(value/100)} é maior que o valor restante ${formatMoney((virtual.value - virtual.value_paid))}. Tem certeza que está correto e deseja prosseguir?`)){
            showNotification({text: 'Operação cancelada.', type: 'warning'}) 
            return
            }
        }

        const vvalue_id = id
        const newRealvalue = {
            stallments, 
            quarter_id, 
            payer_id, unity_id, 
            collaborator_id, 
            vvalue_id,
            payment_date,
            value,
            description
        }
        try{
            const data = await createRealValue(newRealvalue)
            showNotification({text: 'Valor adicionado.', type: 'ok'}) 
            if(getClientValues && setOpenPaymentForm && getData){
                getClientValues()
                getData()
                setOpenPaymentForm(false)
            }
        }catch(error){
            showNotification({text: 'Erro ao cadastrar valor', type: 'error'}) 
        }
    }

    return (
        <form id='addRealValue'>
            {Notification}
            {!!setOpenPaymentForm && <div onClick={() => setOpenPaymentForm(null)} className='closeIcon material-symbols-outlined'>close</div>}
                <h3 className='title'>Adicionar pagamento recebido (valor real)</h3>
                        <label className='label'>ID da receita: {virtual.id} </label>
                        <br/>
                <div className='inputsContainer'>
                    <div className='field'>
                        <label className='label'>Valor pago: </label>
                        <MoneyInput value={money} setValue={setMoney}/>
                    </div>
                    <div className='field'>
                        <label className='label'>Data de pagamento: </label>
                        <input type='date' placeholder="Descrição" onChange={(e) => {
                        const {...newValues} = formValue
                        newValues.date = e.target.value
                        setFormValue(newValues)
                    }} value={formValue.date}/>    
                    </div>
                    <div className='field'>
                        <label className='label'>Observações: </label>
                        <textarea className='input' type='text' placeholder="Observações" onChange={(e) => {
                            const {...newValues} = formValue
                            newValues.description = e.target.value
                            setFormValue(newValues)
                        }} value={formValue.description}/>
                    </div>
                </div>
                <Button externo={true} onclick={handleSubmit} classname='submitBtn' title='Registrar'/>
        </form>
    )
}
export default AddRealValue