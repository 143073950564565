import { useEffect, useRef, useState } from 'react';
import styles from './clients.module.css'
import PageTitle from '../../components/pageTitle';
import { getAllCustomers } from '../../services/axios';
import TableRow from './tableRow';
import ClientsFilter from './clientsFilter';
import AddNewClient from './addNewClient';

const pattern = [{id: 0, name: "carregando nome", description: "carregando descrição"}]

const Clientes = () => {
    
    const [clients, setClients] = useState(pattern)
    const [original, setOriginal] = useState()
    const [selected, setSelected] = useState (null)
    const [isReverse, setIsReverse] = useState(false)
    const [orderBy, setOrderBy] = useState('name')
    const [period, setPeriod]= useState('1')
    const [currentPage, setCurrentPage] = useState(0)

    const getClients = async () => {
        const response = await getAllCustomers(orderBy, isReverse)
        setClients(response)
        setOriginal(response)
    }

    const toggleOrder = (str) => {
        // setCurrentPage(0)

        if (orderBy===str){

            if(isReverse){
                setIsReverse(false)
                // setOrderBy('')
                return
            }
            setIsReverse(true)
            return
        }
        setOrderBy(str)
        return
        
    }
    
    // useEffect(()=>{
    //     if (currentPage===0){
    //         return
    //     }
    //     else{
    //         getClients()
    //     }
    // },[currentPage])

    useEffect(()=>{

        setClients(pattern)
        getClients()

    },[orderBy, isReverse, period])
    
    return(
        <div id='clientes' className={`pageContainer`} >
            <PageTitle title={'Clientes'}/>
            <div className={styles.tableContainer}>
                <div className={styles.functionsRow}>
                    <ClientsFilter original={original} setClients={setClients}/>
                    <AddNewClient getClients={getClients}/>
                </div>
                <table className={styles.table}>
                    <thead>
                        <tr className={styles.labelsRow}>
                            <td className={styles.orderBy}  onClick={() => toggleOrder('name')}>
                                Cliente{`${orderBy==='name' ? isReverse ? '↓' : '↑':''}`}
                            </td>
                            <td className={styles.orderBy} onClick={() => toggleOrder('description')}>
                                Observações{`${orderBy==='description' ? isReverse ? '↓' : '↑':''}`}
                            </td>
                            <td className={`${styles.registered} ${styles.orderBy}`} onClick={() => toggleOrder('num_outstanding_virtual_values')} >
                                Nº de pendencias{`${orderBy==='num_outstanding_virtual_values' ? isReverse ? '↓' : '↑':''}`}
                            </td>
                            <td className={`${styles.registered} ${styles.orderBy}`} onClick={() => toggleOrder('num_virtual_values')} >
                                Total de pagamentos{`${orderBy==='num_virtual_values' ? isReverse ? '↓' : '↑':''}`}
                            </td>
                            <td className={`${styles.registered} ${styles.orderBy}`} onClick={() => toggleOrder('amount_pending')} >
                                Valor pendente{`${orderBy==='amount_pending' ? isReverse ? '↓' : '↑':''}`}
                            </td>
                            <td colSpan={3} className={`${styles.registered}`}>
                                Ações
                            </td>
                        </tr>
                    </thead>
                    <tbody className={styles.valuesTbody}>
                        {clients?.map(((client, index) => (
                            <TableRow getClients={getClients} selected={selected} setSelected={setSelected} key={client.name+index} client={client} />
                            )))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Clientes