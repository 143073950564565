import { useRef, useState } from 'react'
import { updateVirtualValue } from '../../../../../services/axios'
import formatMoney, { unformatMoney } from '../../../../../services/utils/formatMoney'
import { stallmentsOptions } from '../../../../valores/valuesForm'
import styles from '../../tableRow.module.css'
import { MoneyInput } from '../../../../../components/moneyInput'

const Editable = ({showNotification, getData, setShowEditable, payment}) => {
    const [money, setMoney] = useState(formatMoney(payment.value))
    const description_ref = useRef()
    const stallments_ref = useRef()
    const reference_date_ref = useRef()


    const handleUpdate = async () => {

        const value = {
            id: payment.id,
            value: unformatMoney(money) || null,
            description: description_ref.current.value || null,
            reference_date: reference_date_ref.current.value || null,
            stallments: stallments_ref.current.value || null
        }

        try {
            const response = await updateVirtualValue({value})
    
            if (response.status = 200) {
                getData()
                setShowEditable(false)
                showNotification({text: 'Dados Atualizados!', type: 'ok' })
                return
            }
            
        } catch (error) {
            console.log("🚀 ~ error:", error)
            showNotification({text: 'Erro ao atualizar.', type: 'error' })
            
        }

    }
    return(
        <>
        {/* <tr className={styles.editableRow}></tr> */}
                <td>
                    <MoneyInput value={money} setValue={setMoney} />
                </td>
                <td>
                    <select ref={stallments_ref} defaultValue={payment.stallments}>
                        {stallmentsOptions.map((option) => (
                            <option value={option.id}>{option.name}</option>
                        ))}
                    </select>
                </td>
                <td>
                    <textarea ref={description_ref}  className={styles.expandedItem} defaultValue={payment.description}/>
                </td>
                <td>
                    <input ref={reference_date_ref} type='date' className={styles.expandedItem} defaultValue={payment.reference_date}/>
                </td>
                <td className={styles.buttonsContainer}>
                    <input onClick={handleUpdate} type='button' value='Salvar' />
                    <input onClick={() => setShowEditable(false)} type='button' value='Cancelar' />
                </td>
        </>
    )
}

export default Editable