import { useState } from 'react'
import styles from './notification.module.css'

// const types = ['error', 'ok', 'warning']

export const useNotification = () => {
    const [ message, setMessage ] = useState({type: 'ok', text: ''})
    const [ showMessage, setShowMessage ] = useState(false)

    const showNotification = ({text = 'Sem mensagem.', type = 'ok'}) => {
        setMessage({type, text})
        setShowMessage(true)
        setTimeout(() => {
            setShowMessage(false)
        }, 3000);
    }

    const Notification = showMessage?(
        <div className={styles.container}>
            <span className={`material-symbols-outlined ${styles[message.type]}`}>Error</span>
            <h1>{message.text}</h1>
        </div>
    ):''


    
    return {Notification, showNotification}
}