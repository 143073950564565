import { useEffect, useState, useContext } from "react"
import ColabModal from "../colabModal/index.jsx"
import { isoToDateStr } from '../../../services/utils/iso_to_date_string';
import './styles.css'
import { getAllUnities, getCollaboratorProgressions } from "../../../services/axios.js"
import formatMoney, { formatToMoney } from "../../../services/utils/formatMoney.js";
import ProgressionModal from "../progressionModal/index.jsx";
import AddProgressionModal from "../addProgression/index.jsx";

const ColabProgressions = ({progression, blur, setShowEditProgression}) => {
    return (
        <tr className="progressionRow">
            <td>
                {isoToDateStr(progression.date)}
            </td>
            <td>
                {progression.end_date ? isoToDateStr(progression.end_date) : "Até o momento"}
            </td>
            <td>
                {blur && <div className="censoredBar"></div>}
                {!blur && formatToMoney(progression.basic_rem)}
            </td>
            <td>
                {blur && <div className="censoredBar"></div>}
                {!blur && progression.urvs}
            </td>
            <td onClick={() => setShowEditProgression(progression)} className='value tdName addProgression' colSpan={1}>
                <span className="material-symbols-outlined openInFullIcon">edit</span>
            </td>
            {/* <td onClick={() => setShowEditProgression(progression)} className='value tdName addProgression' colSpan={1}>
                <span className="material-symbols-outlined openInFullIcon">delete</span>
            </td> */}
        </tr>
    )
}

const ColabTable = ({getCollabs, values, setValues, blur}) => {
    const [unities, setUnities] = useState([])
    const [showTable, setShowTable] = useState(-1)
    const [progressions, setProgressions] = useState([])
    const [isOpen, setIsOpen] = useState(null)
    const [isAddProgressionOpen, setIsAddProgressionOpen] = useState(null)
    const [showEditProgression, setShowEditProgression] = useState(null)
    const [showAddProgression, setShowAddProgression] = useState(null)

    async function updateProgressionTable(colab_id) {
        setProgressions(await getCollaboratorProgressions(colab_id))
    }

    const toggleShowTable = async (key, colab_id) => {
        
        if (showTable === key) {
            setShowTable(-1)
            setProgressions([])
        }
        else {
            await updateProgressionTable(colab_id)
            setShowTable(key)
        }
    }

    const findUnities = async () => {
        const response = await getAllUnities()
        setUnities(response)
    }

    useEffect(() => {
        findUnities()
    },[])
    return(<>
        <table id='table'>
            <thead>
                <tr className='typeRow'>
                    <th className='tdName'>Colaborador</th>
                    <th className='tdName'>Email</th>
                    <th className='tdName'>Remuneração</th>
                    <th className='tdName'>URVs</th>
                    <th className='tdName' colSpan={2}>Atualizar</th>
                </tr>
            </thead>
            <tbody>
                {values?.map((colab, index) => 
                <>
                <tr key={index} className={`valueRow colabRow`}>
                    <td onClick={() => toggleShowTable(index, colab.id)} className='value tdName'>
                        {colab.name}
                    </td>
                    <td onClick={() => toggleShowTable(index, colab.id)} className='value tdName'>
                        {colab.email}
                    </td>
                    <td onClick={() => toggleShowTable(index, colab.id)} className='value tdName' >
                        {blur && <div className="censoredBar"></div>}
                        {!blur && formatToMoney(colab.progression.basic_rem)}
                    </td>
                    <td onClick={() => toggleShowTable(index, colab.id)} className='value tdName' >
                        {blur && <div className="censoredBar"></div>}
                        {!blur && colab.progression.urvs}
                    </td>
                    <td className='value tdName progEditIcon' onClick={() => setIsOpen(colab)} colSpan={2}>
                        <span className="material-symbols-outlined openInFullIcon">edit</span>
                    </td>
                </tr>
                {showTable == index && 
                <>
                    <tr className='typeRow'>
                        <th className='tdName child_coll_row'>
                            Data de início
                        </th>
                        <th className='tdName child_coll_row'>
                            Data final
                        </th>
                        <th className='tdName child_coll_row'>
                            Remuneração
                        </th>
                        <th className='tdName child_coll_row'>
                            URVs
                        </th>
                        <th colSpan={2} className='tdName child_coll_row'>
                            <button className="addProgression" onClick={() => setShowAddProgression(colab)}>
                                Adicionar
                            </button>
                        </th>
                    </tr>
                    {progressions?.map((progression, index) => 
                    <ColabProgressions progression={progression} blur={blur} setShowEditProgression={setShowEditProgression}/>)}
                </>}
                </>
            )}
            </tbody>
        </table>
                    {showAddProgression && <AddProgressionModal updateProgressionTable={updateProgressionTable} colab={showAddProgression} unities={unities} modalIsOpen={showAddProgression} setIsOpen={setShowAddProgression} />}
                    {showEditProgression && <ProgressionModal updateProgressionTable={updateProgressionTable} progression={showEditProgression} unities={unities} modalIsOpen={showEditProgression} setIsOpen={setShowEditProgression}/>}
                    {isOpen && <ColabModal getCollabs={getCollabs} colab={isOpen} index={1} unities={unities} modalIsOpen={isOpen} setIsOpen={setIsOpen}/>} 
                </>
    )
}

export default ColabTable