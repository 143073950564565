import { useContext, useEffect } from 'react'
import './styles.css'
import {AuthContext} from '../../contexts/auth'
import { LoginBtn } from '../../components/GoogleBtns'
import Header from '../../components/header'
 

const LoginPage = () => {
    return (
        <div id='login'>
            <div className="login_card">
            <div className="header_container">
                <Header/>
            </div>
            <h1 className='title'>Login</h1>
            <div className='google_button'>
                <LoginBtn/>
            </div>
            </div>
        </div>
    )
}

export default LoginPage