import { Link } from 'react-router-dom'

export default function NavBarLink ({selected, btn, setSelected}) {
    function handleClick (){
        setSelected(btn)
    }
    return(
        <Link 
            className={selected?.class===btn.class?'btn selected':'btn'}
            to={btn.link}
            onClick={handleClick}>
            <span className='btnName' >{btn.name}</span>
        </Link>
    )
}