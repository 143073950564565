import Cookies from "js-cookie"

export const getTokens = () => {

    const c_token = Cookies.get('access_token')
    const r_token = Cookies.get('refresh_token')

    let access_token
    let refresh_token

    if (c_token) {
        access_token = JSON.parse(c_token)
    }
    if (r_token) {
        refresh_token = JSON.parse(r_token)
    }
    if (!r_token) {
        const button = document.querySelector('.logout_button')
        if (button) {
            button.click()
            return
        }
    }

    if (!c_token) {
        access_token = null
    }

    return { access_token, refresh_token }
}