export const dateSlug = (num) => {
    const slugs = {
        '01': "jan",
        '02': "feb",
        '03': "mar",
        '04': "apr",
        '05': "may",
        '06': "jun",
        '07': "jul",
        '08': "aug",
        '09': "sep",
        '10': "oct",
        '11': "nov",
        '12': "dec"
    }
    return (slugs[num])
}

export const translateQuarter = (en) => {
    if (!en) {
        return
    }
    const name = en.split('/')
    const slugs = {
        'jan': 'Jan',
        'feb': 'Fev',
        'mar': 'Mar',
        'apr': 'Abr',
        'may': 'Mai',
        'jun': 'Jun',
        'jul': 'Jul',
        'aug': 'Ago',
        'sep': 'Set',
        'oct': 'Out',
        'nov': 'Nov',
        'dec': 'Dez'
    }
    return slugs[(name[0].toLowerCase())] + '/' + name[1]
}

export const findNumberByQuarter = (quarterSlug) => {
    const name = quarterSlug?.split('/')
    const slugs = {
        'jan': '01',
        'feb': '02',
        'mar': '03',
        'apr': '04',
        'may': '05',
        'jun': '06',
        'jul': '07',
        'aug': '08',
        'sep': '09',
        'oct': '10',
        'nov': '11',
        'dec': '12'
    }
    return slugs[(name[0].toLowerCase())]
}

export const slugToTx = (slug) => {
    const slugs = {
        'jan': 'T1',
        'abr': 'T2',
        'jul': 'T3',
        'out': 'T4',

        'apr': 'T2',
        'oct': 'T4',

        '01': 'T1',
        '04': 'T2',
        '07': 'T3',
        '10': 'T4',
    }
    return slugs[slug.toLowerCase()]
}
