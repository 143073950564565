import { useEffect, useState } from 'react'
import formatMoney from '../../../services/utils/formatMoney'
import './styles.css'

export default function UrvTable ({data}){
    const {virtual, real, distributed, urv_price, urvs, name, collaborators, virtual_total_distributed} = data || ''
    const urv_virtual_price = virtual_total_distributed / urvs

    const [showTable, setShowTable] = useState(false)
    const [totalPaid, setTotalPaid] = useState()
    
    useEffect(() => {
        let sum = 0
        collaborators?.map((colab, index) => (
            // <tr key={index}>
            //     <td className='label nome'>{colab.name}</td>
            //     <td>{colab.urvs}</td>
            //     <td>{formatMoney(urv_virtual_price * colab.urvs)}</td>
            //     <td>{formatMoney(distributed)}</td>
            //     <td>{formatMoney(colab.paid_var_rem)}</td>
            //     <td>{formatMoney((urv_virtual_price * colab.urvs) - colab.paid_var_rem)}</td>
            // </tr>
            sum += colab.paid_var_rem
        ))
        setTotalPaid(sum)
    }, [])

    function toFixed(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    return (
        <table id='urvtable'>
            <thead>
                <tr>
                    <th className='title'>{name} - Remuneração variável</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='label'>Faturamento Virtual: </td>
                    <td>{formatMoney(virtual)}</td>
                </tr>
                <tr>
                    <td className='label'>Faturamento real:</td>
                    <td>{formatMoney(real)}</td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td className='label'>Total RV(virtual):</td>
                    <td>{formatMoney(virtual_total_distributed)}</td>
                </tr>
                <tr>
                    <td className='label'>Total RV(real):</td>
                    <td>{formatMoney(distributed)}</td>
                </tr>
            </tbody>
            <tbody>
                <tr>
                    <td className='label'>Total de URV (pool):</td>
                    <td>{urvs.toString().replace('.',',')}</td>
                    </tr>
                <tr>
                    <td className='label'>Valor por URV (real) </td>
                    <td>{formatMoney(urv_price)}</td>
                </tr>
            </tbody>
            <tbody className='colabTBody'>
                    <tr className='headTitles'>
                        <td className='label nome'>Nome</td>
                        <td className='label'>URVS</td>
                        <td className='label'>Total Virtual</td>
                        <td className='label'>Total Real</td>
                        <td className='label'>Total Pago</td>
                        <td className='label'>Total a receber</td>
                    </tr>
                <tr style={{cursor:'pointer'}} onClick={() => setShowTable(!showTable)}>
                    <td className='label nome'></td>
                    <td></td>
                    <td><span className="material-symbols-outlined">{!showTable ? "visibility_off" : "visibility"}</span></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                {showTable &&
                    <>
                    {collaborators?.map((colab, index) => (
                    <>
                    {
                        colab.urvs > 0 &&
                        <tr key={index}>
                            <td className='label nome'>{colab.name}</td>
                            <td>{colab.urvs}</td>
                            <td>{formatMoney(urv_virtual_price * colab.urvs)}</td>
                            <td>{formatMoney(toFixed((distributed/urvs)*colab.urvs,2))}</td>
                            <td>{formatMoney(colab.paid_var_rem)}</td>
                            <td>{formatMoney(toFixed((distributed/urvs)*colab.urvs - colab.paid_var_rem,2))}</td>
                        </tr>
                    }
                    </>
                    ))}
                    {collaborators.length > 0 && <tr style={{cursor:'pointer'}} onClick={() => setShowTable(!showTable)}>
                        <td className='label nome'></td>
                        <td>{urvs}</td>
                        <td>{formatMoney(virtual_total_distributed)}</td>
                        <td>{formatMoney((distributed*urvs)/urvs)}</td>
                        <td>{formatMoney(totalPaid)}</td>
                        <td>{formatMoney(distributed-totalPaid)}</td>
                    </tr>}
                    </>
                }
            </tbody>
        </table>
    )
}