import './styles.css'
import ExpensesTable from './expensesTable';
import ExpensesForm from './expensesForm';
import { useContext, useEffect, useState } from 'react'
import { expensesByDate, getAllUnities } from '../../services/axios';
import PageTitle from '../../components/pageTitle';
import Filter from '../metricas/filter';
import { DatePickerContext } from '../../contexts/datePickerCtx';
import LoadContainer from '../../components/loading'
import styles from '../payments/payments.module.css'
import { useNotification } from '../../components/useNotification';

const Despesas = () => {
	const {Notification, showNotification } = useNotification()
	const {period, button_ref, formatCurrentDate, handleSubmit, unity } = useContext(DatePickerContext)
    const[orderBy, setOrderBy] = useState('name')
    const[isReverse, setIsReverse] = useState(false)
    const [expenses,setExpenses] = useState([])
	const [unities, setUnities] = useState([])

    const [isLoading, setIsLoading] = useState(false)

	const [showUda,setShowUda] = useState(true)
    const [showUdf,setShowUdf] = useState(true)
    const [showEscritorio, setShowEscritorio] = useState(true)
    const [showTotal, setShowTotal] = useState(true)

	const findUnities = async () => {
		const all_unities = await getAllUnities()
		setUnities(all_unities)
	}
	useEffect(() => {
		findUnities()
	},[])

    const getExpensesByDate = async (reset = false) => {
        setIsLoading(true)

        const {date, end_date} = formatCurrentDate()
        if(!date){
            console.log('erro ao processar data. -> ', date)
            if (reset){ showNotification({type: 'warning', text: 'Por favor, preencha o campo com a data.'}) }
            setIsLoading(false)
            return
        }

        const no_filter = await expensesByDate({
            date: date,
            end_date: end_date,
            by_month: period==='by_month',
            by_quarter: period==='by_quarter',
            isReverse: isReverse,
            orderBy: orderBy==='created_at',
        })
		let response = no_filter
        if(unity !== 'todas'){
            response = no_filter.filter((item) => item.id===parseInt(unity))
        }

		if (!response){
            setIsLoading(false)
            return
        }
		
        setExpenses(response)
        setIsLoading(false)
    }

	// const getRecurrentExpenses = async () => {
    //     const response = await getRecurrent()
    //     setRecurrent(response)
    // }

	useEffect(()=>{
	    setExpenses([])
        getExpensesByDate()
        button_ref.current.onclick = () => handleSubmit(getExpensesByDate)
	},[period, unity])

    return(
        <div id='despesas' className='pageContainer'>
			{Notification}
            {isLoading && <LoadContainer/>}
			<PageTitle title={'Despesas'} />
			<div className='topBtns'>
			</div>
			
			<ExpensesForm getExpensesByDate={getExpensesByDate}/>
            <div className='valuesContainer'>
							<Filter showUda={showUda} 
									showUdf={showUdf} 
									showEscritorio={showEscritorio} 
									showTotal={showTotal}
									setShowUda={setShowUda} 
									setShowUdf={setShowUdf} 
									setShowEscritorio={setShowEscritorio}
									setShowTotal={setShowTotal}
									isTotalVisible={false}
							/>
					<div className={styles.tableContainer}>
					<h2 className='subtitle'>Despesas do período</h2>

					{
					expenses?.map((unity, i) => {
						if (!showUda && unity.name==='UDA'){
							return ('')
						}else if(!showUdf && unity.name==='UDF'){
							return ('')
						}else if(!showEscritorio && unity.name==='Escritório'){
							return ('')
						}
							return(
                            <ExpensesTable key={unity.name} getExpensesByDate={getExpensesByDate} unities={unities} title={`Despesas do período`} unity={unity}/>
						)
						})
					}
					</div>
			</div>
        </div>
    )
}

export default Despesas