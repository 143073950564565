import { useState } from "react";
import styles from './editable.module.css'

const ThisLabel = ({label}) => {
    return(
        <label>{label}</label>
    )
}
const ThisCheckbox = ({value, refConst}) => {
    const [checked, setChecked] = useState(value);
    value = checked
    return (
        <input onChange={() => setChecked(prev => !prev)} ref={refConst} type="checkbox" checked={checked}/>
    )
}

const ThisDropdown = ({array, refConst, defaultValue}) => {
    return(
        <select ref={refConst} defaultValue={defaultValue}>
            {array.map((item) => (
                <option key={item.id} value={item.id}>{item.name}</option>
            ))}
        </select>
    )
}

const ThisInput = ({type, refConst, defaultValue}) => {
    return (
        <input ref={refConst} type={type} defaultValue={defaultValue}/>
    )
}

const EditableContainer = ({children}) => {

    return(
        <div className={styles.field}>
            {children}
        </div>
    )
}

export const EditableComponent = {
    Label: ThisLabel,
    Input: ThisInput,
    Container: EditableContainer,
    Checkbox: ThisCheckbox,
    Dropdown: ThisDropdown
}