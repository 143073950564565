import { useEffect, useRef, useState } from 'react';
import { createExpense, getAllUnities } from '../../../services/axios';
import './styles.css'
import Button from '../../../components/button';
import { MoneyInput } from '../../../components/moneyInput';
import { today } from '../../../components/today';
import { useNotification } from '../../../components/useNotification';
import { unformatMoney } from '../../../services/utils/formatMoney';

const ExpensesForm = ({getExpensesByDate}) => {
    const pattern = {name: '', value: '', quarter_id:'', stallments: 0, description: '', recurrent: false, unity_id: '0'}
    const [values, setValues] = useState(pattern)
    const [unities, setUnities] = useState()
    const [money,setMoney] = useState('')
    const dateRef = useRef()
    const paymentDateRef = useRef()
    const { Notification, showNotification } = useNotification()

    const handleSubmitValues = async (e) => {
        e.preventDefault()
        document.querySelector('.expensesBtn').disabled = true
        const date = new Date(dateRef.current.value.toString()).toISOString()
        let payment_date = ''
        
        if (paymentDateRef!=='') {
            payment_date = new Date(paymentDateRef.current.value.toString()).toISOString()
        }
        values.reference_date = date
        values.payment_date = payment_date
        values.value = unformatMoney(money)

        try {
            const response = await createExpense(values)
                console.log("🚀 ~ response:", response.data)
                setValues(pattern)
                document.querySelector('.expensesBtn').disabled = false
                await getExpensesByDate()
        } catch (error) {
            showNotification({text: 'Erro ao cadastrar despesa.', type: 'error'})
            console.log("🚀 ~ error:", error)
            Promise.reject(error)
        } finally {
            window.location.reload()
        }
        
    }

    const getUnities = async () => {
        const data = await getAllUnities()
        setUnities(data)
    }

    useEffect(()=> {
        getUnities()
    }, [])


    return(
        <form id='containerCadastrar'>
            {Notification}
                <div className='expensesFieldContainer'>
                    <div className='field'>
                        <label>Nome da despesa: </label>
                        <input required type='text' className='input' 
                        value={values.name} onChange={(e) => {
                            const {...oldValues} = values
                            oldValues.name = e.target.value
                            setValues(oldValues)
                        }} 
                        placeholder='Nome da Despesa'></input>
                    </div>
                    <div className='field'>
                        <label>Valor: </label>
                        <MoneyInput value={money} setValue={setMoney}/>
                    </div>
                    <div className='field'>
                        <label>Observações: </label>
                        <textarea required type='text' className='input'
                        value={values.description} onChange={(e) => {
                            const {...oldValues} = values
                            oldValues.description = e.target.value
                            setValues(oldValues)
                        }} 
                        placeholder='Observações'></textarea>
                    </div>
                    <div className='field'>
                        <label>Quantidade de parcelas: </label>
                        <input required type='number' className='input'
                        value={values.stallments} onChange={(e) => {
                            const {...oldValues} = values
                            oldValues.stallments = e.target.value
                            setValues(oldValues)
                        }} 
                        placeholder='Qntd de parcelas'></input>
                    </div>
                    <div className='field'>
                        <label>Data de referência: </label>
                        <input required type='date' className='dateField' 
                        ref={dateRef} 
                        defaultValue={`${today}`}
                        ></input>
                    </div>
                    <div className='field'>
                        <label>Data de Pagamento: </label>
                        <input required type='date' className='dateField' 
                        ref={paymentDateRef} 
                        ></input>
                    </div>
                    <div className='field'>
                        <label htmlFor='recorrente'className='label'>Recorrente?</label>
                        <input type='checkbox' 
                        checked={values.recurrent} onChange={() => {
                            const {...oldValues} = values
                            oldValues.recurrent =  !oldValues.recurrent
                            setValues(oldValues)
                        }}  
                        name='recorrente' id='recorrente'></input>
                    </div>
                    <div className='field'>
                        <label>Unidade: </label>
                        <select defaultValue={`${unities?[0].id:1}`}
                        onChange={(e) => {
                            const {...oldValues} = values
                            oldValues.unity_id = parseInt(e.target.value)
                            setValues(oldValues)
                        }}
                        name="unitySelect" id="unitySelect">
                        <option value='1' disabled hidden>Setor responsável</option>
                        {unities?.map((unity,index) => 
                                <option key={index} value={unity.id}>{unity.name}</option>
                        )}
                        </select>
                    </div>
                <Button externo={true} classname={'expensesBtn'} onclick={handleSubmitValues} title={'Cadastrar Nova Despesa'}/>
                </div>
            </form>
    )
}

export default ExpensesForm