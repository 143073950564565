import { useState } from "react"
import ClientModal from "./clientModal"
import styles from './tableRow.module.css'
import { getCustomerPendencies, invalidateCustomer } from "../../../services/axios"
import PaymentField from "./paymentField"
import { useNotification } from "../../../components/useNotification"
import EditableClient from "./editable"
import formatToMoney from "../../../services/utils/formatMoney"
import Editable from "./paymentField/editable"

const sortById = (a, b) => {
    if (a.id > b.id) {
        return 1
    }
    if (a.id < b.id) {
        return -1
    }
    return 0
}

const TableRow = ({getClients, client, selected, setSelected}) => {
    const {Notification, showNotification } = useNotification()

    const isModalOpen = selected?.id === client?.id
    
    const [payments, setPayments] = useState([])
    const [showEditable, setShowEditable] = useState(false)
    const [showPaymentEditable, setShowPaymentEditable] = useState(null)

    const hasPayments = payments.length > 0
    const openModal = () => {
        setSelected(client)
    }

    const closeModal = () => {
        setSelected(null)
    }

    const deleteItem = async() => {
        if ( window.confirm(`Tem certeza que deseja DELETAR o cliente ${client.name} e id ${client.id}?`) ) {
          try{
            await invalidateCustomer(client.id)
            showNotification({text: 'Cliente deletada!', type:'ok'})
            getClients()
            return
          } catch(error) {
            showNotification({type: 'error', text: "Cliente possui receitas." })
          }
      }
    }

    const getData = async () => {
        const pendencies_response = await getCustomerPendencies({customer_id: client.id})
        if (pendencies_response.length === 0) {
            showNotification({type: 'warning', text: "Sem pagamentos registrados!"})
            return
        }
        setPayments(pendencies_response.sort((a, b) => sortById(a,b))) 
    }

    const expand = async () => {
        if (hasPayments){
            setPayments([])
            return
        }
        await getData()
    }
  
    return (
        <>
        {Notification}
        <tr>
            <td onClick={expand} className={`${styles.field} ${styles.pointer}`}>
                {client.name}
                <span className={styles.tooltip}>{hasPayments?'Minimizar pagamentos':'Expandir pagamentos'}</span>
            </td>
            <td onClick={expand} className={`${styles.field} ${styles.pointer}`}>
                {client.description}
                <span className={styles.tooltip}>{hasPayments?'Minimizar pagamentos':'Expandir pagamentos'}</span>
            </td>


            <td onClick={expand} className={styles.numVirtualValues}>
                {client.num_outstanding_virtual_values}
                <span className={styles.tooltip}>{hasPayments?'Minimizar pagamentos':'Expandir pagamentos'}</span>
            </td>
            <td onClick={expand} className={styles.numVirtualValues}>
                {client.num_virtual_values}
                <span className={styles.tooltip}>{hasPayments?'Minimizar pagamentos':'Expandir pagamentos'}</span>
            </td>
            <td onClick={expand} className={styles.numVirtualValues}>
                {formatToMoney(client.amount_pending)}
                <span className={styles.tooltip}>{hasPayments?'Minimizar pagamentos':'Expandir pagamentos'}</span>    
            </td>
            <td>
            <span onClick={openModal} className={`${styles.icon} material-symbols-outlined`}>
                add
                <span className={styles.tooltip}>Adicionar Pagamento</span>            
            </span>
            </td>
            <td>
            <span onClick={() => setShowEditable(prev => !prev)} className={`${styles.editIcon} ${styles.icon} material-symbols-outlined`}>
                edit
                <span className={styles.tooltip}>Editar Pagamento</span>
            </span>
            </td>
            <td>
            <span onClick={deleteItem} className={`${styles.deleteIcon} ${styles.icon} material-symbols-outlined`}>
                delete
                <span className={styles.tooltip}>Deletar Pagamento</span>
            </span>
            </td>
            
        </tr>
        <tr className={styles.labels}>
            {payments?.length > 0 && (
                <>
                        {/* <th className={styles.id}>ID</th> */}
                        <th className={styles.id} >VALOR</th>
                        <th className={styles.id}>PARCELAS</th>
                        <th >DESCRIÇÃO</th>
                        <th className={styles.pendency}>DATA DE REFERÊNCIA</th>
                        <th className={styles.pendency}>VALOR PENDENTE</th>
                        {/* <th className={styles.pendency}>DATA DE PAGAMENTO</th> */}
                </>
            )}
        </tr>
            {payments?.map((payment, index) => (
                <>
                <tr className={styles.tBodyExpanded}>
                        <PaymentField showNotification={showNotification} showEditable={showPaymentEditable} setShowEditable={setShowPaymentEditable} getData={getData} key={payment.id} payment={payment} getClients={getClients}/>
                </tr>
                <tr className={styles.editableRow}>
                    {showPaymentEditable == payment.id && <Editable showNotification={showNotification} getData={getData} setShowEditable={setShowPaymentEditable} payment={payment}/>}
                </tr>
                {payment.id == payments[payments.length-1].id && <br></br>}
            </>
            ))}
        {isModalOpen && <ClientModal closeModal={closeModal} isModalOpen={isModalOpen} client={client} showNotification={showNotification}/>}
        {showEditable && <EditableClient showNotification={showNotification} getClients={getClients} client={client} setShowEditable={setShowEditable}/>}
        </>
    )
}

export default TableRow