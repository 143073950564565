import Cookies from "js-cookie"

export const StoragePeriod = ({ data }) => {
    Cookies.set('date_picker', JSON.stringify(data), { expires: 30 })
}

export const getPeriodFromCookies = () => {
    const cookie = Cookies.get('date_picker')
    if (cookie) {
        return JSON.parse(cookie)
    }

    const this_year = new Date().getFullYear()
    return {
        period: "by_year",
        date: `${this_year}-01-01T02:00:00.000Z`,
        end_date: "",
        unity: 'todas'
    }

}