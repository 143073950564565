import { AuthContext } from '../../contexts/auth'
import NavBarLink from './Link'
import './styles.css'
import { useContext, useEffect, useState } from 'react'
import UserCard from './userCard'

const btns = [
	// { name: 'Início', class: 'inicial', link: '/home' },
	{name: 'Clientes', class: 'clientes', link: '/clientes'},
	{name: 'Receitas', class: 'valores', link: '/cadastrarvalor'},
	{name: 'Despesas', class: 'despesas', link: '/despesas' },
	{name: 'Colaboradores', class: 'colaboradores', link: '/payments'},
	{name: 'Relatórios', class: 'metricas', link: '/metricas'},
]
const TopBar = () => {
	const [selected,setSelected] = useState()
	
	useEffect(() => {
		const found = btns.find((item) => item.link===`/${window.location.href.split('/')[3]}`)
		setSelected(found)
	}, []);
	return (
		<div id='btnsContainer'>
			{btns.map((btn, i) => (
				<NavBarLink key={i} btn={btn} selected={selected} setSelected={setSelected}/>
			))}
			<UserCard/>
		</div>
	)
}

export default TopBar
