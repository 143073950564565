import Cookies from "js-cookie"

export const StorageCookies = ({ access_token, refresh_token, user }) => {
    const domain = process.env.REACT_APP_DOMAIN

    if (user) {
        console.log("🚀 ~ Guardou user:", user)
        Cookies.set('user', JSON.stringify(user), { expires: 1, domain: domain })
    }
    if (access_token) {
        console.log("🚀 ~ Guardou access:", access_token)

        Cookies.set('access_token', JSON.stringify(access_token), { expires: 1, domain: domain })
    }
    if (refresh_token) {
        console.log("🚀 ~ Guardou refresh:", refresh_token)
        Cookies.set('refresh_token', JSON.stringify(refresh_token), { expires: 5, domain: domain })
    }
}

export const removeCookies = () => {
    const domain = process.env.REACT_APP_DOMAIN
    Cookies.remove('user', { domain: domain })
    Cookies.remove('refresh_token', { domain: domain })
    Cookies.remove('access_token', { domain: domain })
}