import styles from './index.module.css'

const ClientsFilter = ({original, setClients}) => {

    const filter = (e) => {
        const filtered = original.filter((client) => 
        client.name.toUpperCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(e.target.value.toUpperCase())
        || client.name.toUpperCase().includes(e.target.value.toUpperCase())
        )
        setClients(filtered)
    }

    return(
            <div className={styles.container}>
                <label>Pesquisar Clientes: </label>
                <input type="text" onChange={(e) => filter(e)}/>
            </div>
    )
}

export default ClientsFilter