import { useRef, useState } from 'react'
import './styles.css'
import { month, year } from '../../../components/today'
import { findQuarterByMonthAndYear, registerPayment } from '../../../services/axios'
import { dateSlug } from '../../../services/utils/dateSlug'
import Button from '../../../components/button'
import {MoneyInput, parseNumber} from '../../../components/moneyInput';
import { useNotification } from '../../../components/useNotification'
import { unformatMoney } from '../../../services/utils/formatMoney'

const PaymentsForm = ({collabs, index, getData}) => {
    const {Notification, showNotification }= useNotification()
    const [typeOfRem, setTypeOfRem] = useState('remuneração')
    const [money, setMoney] = useState('')
    const [paid, setPaid] = useState(false)
    const collabRef = useRef()
    const dateRef = useRef()
    const descriptionRef = useRef()

    const toogleRadio = (e) => {
        setTypeOfRem(e.target.value)
    }
    const resetForm = () => {
        dateRef.current.value = `${year}-${month}`
        collabRef.current.value = 0
        setMoney(0)
        descriptionRef.current.value = ''
    }
    const sendInfo = async(e) => {
        e.target.disabled = true
        const selectedMonth = dateRef.current.value.slice(5,7)
        const selectedYear = dateRef.current.value.slice(0,4)
        const date = new Date(dateRef.current.value).toISOString()
        const overwrite =(typeOfRem==='remuneração'?'?overwrite=true':'')
        const data = {
            reference_date: date,
            value: unformatMoney(money),
            collaborator_id: collabRef.current.value,
            description: descriptionRef.current.value,
            name: typeOfRem,
            paid:  paid,
        }           
       try {
           const response = await registerPayment({data, overwrite})
           if(response.status === 200){
            showNotification({text:`Pagamento cadastrado!`, type: 'ok' })
           }
           resetForm()
           getData()
       } catch (error) {
        showNotification({text:`Pagamento não cadastrado.`, type: 'error' })
            console.log("🚀 ~ error:", error)
            Promise.reject(error)
       }finally{
            e.target.disabled = false
       }
    }
    return(
        <>
        {Notification}
            <form id='paymentsForm'>
                <div className='field'>{index} - </div>
                <div className='field'>
                    <label>Colaborador:</label>
                    <select ref={collabRef}>
                        {collabs.map((collab) =>
                        <option value={collab.id}>{collab.name}</option>
                        )}
                    </select>
                </div>
                <div className='field'>
                    <label>Mês: </label>
                    <input ref={dateRef} type='month' defaultValue={`${year}-${month}`} />
                </div>
                <div className='field remType'>
                    <span>Tipo: </span>
                    <div>
                        <label htmlFor={`rem${index}`}>Remuneração Fixa</label>
                        <input checked={typeOfRem=== 'remuneração'} onChange={toogleRadio} type='radio' id={`rem${index}`} name='tipo' value='remuneração'/>
                    </div>
                    <div>
                        <label htmlFor={`bonus${index}`}>Ressarcimento</label>
                        <input checked={typeOfRem === 'ressarcimento'} onChange={toogleRadio} type='radio' id={`bonus${index}`} name='tipo' value='ressarcimento'/>
                    </div>
                    <div>
                        <label htmlFor={`bonus${index}`}>Remuneração variável</label>
                        <input checked={typeOfRem === 'bônus'} onChange={toogleRadio} type='radio' id={`bonus${index}`} name='tipo' value='bônus'/>
                    </div>
                </div>
                <div className='field'>
                    <label>Valor: </label>
                    <MoneyInput value={money} setValue={setMoney} />
                </div>
                <div className='field'>
                    <label>Observações: </label>
                    <input ref={descriptionRef} type="text" placeholder='Observações(opcional)'/>
                </div>
                <div className='field'>
                    <label>Pago: </label>
                    <input type="checkbox" checked={paid} onClick={() => setPaid(!paid)}/>
                </div>
                <Button externo={true} classname='submitPayments' onclick={sendInfo} title='Enviar'/>
            </form>
        </>
    )
}

export default PaymentsForm