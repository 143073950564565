import { useEffect, useState } from "react"
import { getRealValues, invalidadeRealValue } from "../../../../services/axios"

import './styles.css'
import formatMoney from "../../../../services/utils/formatMoney"
import { useNotification } from "../../../../components/useNotification"

const RealValue = ({vvalue_id}) => {
    const {Notification, showNotification } = useNotification()
    const [realValues, setRealValues] = useState()

    const handleDeleteRealValue = async(real_value_id) => {
        if (window.confirm('Tem certeza que deseja deletar o Valor Real?')){
            try {
                await invalidadeRealValue(real_value_id)
                showNotification({text: 'Valor deletado!', type: 'ok'})
            } catch (error) {
                showNotification({text: 'Não foi possível deletar o valor real.', type: 'error'})
            }finally{
                window.location.reload()
            }
        }else{
            showNotification({text: 'Operação cancelada.', type: 'warning'})
        }
    }

    const getRealValuesByVirtualId = async () => {
        const data = await getRealValues(vvalue_id)
        setRealValues(data)
    }   

    useEffect(() => {
        getRealValuesByVirtualId()
    },[vvalue_id])
    return(
        <section id='realValue' className='section'>
            {Notification}
            {realValues?.map((value, index) => (
                <div key={index} className='container'>
                    <div className="row">
                        <div className="field">
                            <label>Valor: </label>
                            <span>{formatMoney(value.value)}</span>
                        </div>
                        <div className="field">
                            <label>Descrição: </label>
                            <span>{value.description || "Sem Descrição"}</span>
                        </div>
                        <div className="field">
                            <label>Data de pagamento: </label>
                            <span>{value.payment_date.slice(0,10).split('-').reverse().join('/')}</span>
                        </div>
                    </div>
                    <div className="field">
                        <span onClick={() => handleDeleteRealValue(value.id)} className="material-symbols-outlined deleteIcon">delete</span>
                    </div>
                </div>
            ))}
        </section>
    )
}

export default RealValue