import { useRef, useState } from 'react'
import styles from './addNewClient.module.css'
import { createCustomer } from '../../../services/axios'
import { useNotification } from '../../../components/useNotification'

const AddNewClient = ({getClients}) => {
    const [showForm, setShowForm] = useState(false)
    const { Notification, showNotification } = useNotification()

    const name_ref = useRef()
    const description_ref = useRef()

    const createClient = async () => {
        if (!name_ref.current.value){
            showNotification({text: 'Digite o nome do cliente.', type: 'error'})
            return
        }
        try {
            const response = await createCustomer({
                name: name_ref.current.value || null,
                description: description_ref.current.value || null,
            })
            if (response.status ===200) {
                showNotification({text: 'Cliente Cadastrado!', type: 'ok'})
                getClients()
            } else{
                showNotification({text: 'Erro ao cadastrar cliente.', type: 'error'})
            
            }
        } catch (error) {
            Promise.reject(error)
            showNotification({text: 'Erro ao cadastrar cliente.', type: 'error'})
        } finally {
            name_ref.current.value = null
            description_ref.current.value = null
        }
    }

    return(
        <div>
            {Notification}
            <span className={styles.title} onClick={() => setShowForm(prev => !prev)}>
                {showForm?`Fechar`:`Adicionar Novo Cliente +`}
                </span>
            {showForm && (
                <>
                <div className={styles.container}>
                    <div className={styles.field}>
                        <label>Nome:</label>
                        <input required ref={name_ref} type='text' placeholder='Nome do Cliente' />
                    </div>
                    <div className={styles.field}>
                        <label>Observações:</label>
                        <input ref={description_ref} type='text' placeholder='Observações' />
                    </div>
                    <div className={`${styles.field} ${styles.buttonField}`}>
                        <input onClick={createClient} type='button' value='Enviar' />
                    </div>
                </div>
                </>
            )}
        </div>
    )
}

export default AddNewClient