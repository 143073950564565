import { useState } from "react"
import formatMoney from "../../../../services/utils/formatMoney"

const Collab = ({collab}) => {
    const [showContent, setShowContent] = useState(false)
    if (collab.payments.length>0){
        return(
            <>
                <tr className="borderTop" onClick={() => setShowContent(prev => !prev)}>
                    <td className="label topTitles name">{collab.name}</td>
                    <td className="topTitles"></td>
                    <td className="topTitles"></td>
                    <td className="topTitles"></td>
                    <td className="material-symbols-outlined topTitles hideShowLabel">{showContent?'visibility_off':'visibility'}</td>
                </tr>
                {showContent?(
                    <>
                <tr className="marginLeftRow">
                    <td className="label">Tipo</td>
                    <td className="label">Valor</td>
                    <td className="label">Status</td>
                    <td className="label">Descrição</td>
                    <td className="label">Data de pagamento</td>
                </tr>
                    {collab.payments?.map((payment, index)=> (
                    <tr className="marginLeftRow" key={index}>
                    <td>{payment.name}</td>
                            <td>{formatMoney(payment.value)}</td>
                            <td>{payment.paid?'Pago':'Pendente'}</td>
                            <td>{payment.description || 'Sem descrição'}</td>
                            <td>{payment.payment_date.slice(0,10).split('-').reverse().join('/') || ''}</td>
                        </tr>
                    ))}
                    </>
                ):''}
            </>
        )
    }
}

export default Collab