import { useEffect, useState } from "react"
import { deleteExpense, updateExpense, updatePaidExpense } from "../../../../services/axios"
import formatMoney from "../../../../services/utils/formatMoney"
import Editable from "./editable"
import { useNotification } from "../../../../components/useNotification"
import { isoToDateStr } from "../../../../services/utils/iso_to_date_string"
import styles from "../styles.css"
const Item = ({getExpensesByDate, unities, row}) => {
    const [showEditable, setShowEditable] = useState(false)
    const {Notification, showNotification } = useNotification()
    const [rowStatus, setRowStatus] = useState(row.paid)
    const [responseState, setResponseState] = useState()

    const handleDeleteExpense = async ()=>{
        if (window.confirm('Tem certeza que deseja deletar essa despesa?')){
            try {
                await deleteExpense(row.id)
                getExpensesByDate()
                showNotification({text: 'Despesa deletada!', type: 'ok'})
            } catch (error) {
                Promise.reject(error)
                showNotification({text: 'Erro ao deletar despesa.', type: 'error'})
            }
        }else{
            showNotification({text: 'Operação cancelada. Nada foi deletado.', type: 'warning'})
        }
    }

    const switchExpensePaidStatus = async () => {
        const response = await updatePaidExpense({id:row?.id, paid:!rowStatus})
        if (response.paid != rowStatus) {
            setRowStatus(response.paid)
        }
    }

    // useEffect(() => {

    // }, [rowStatus])

    return(
        <>
        {Notification}
        <tr className='valueRow despesasRow'>
            <td className='value tdName'>
                {row?.name}
            </td>
            <td className='value'>
                {row?.description}
            </td>
            <td className='value tdValue'>
                {isoToDateStr(row.reference_date)}
            </td>
            <td className='value tdValue'>
                {formatMoney(row?.value)}
            </td>
            <td className='value tdValue'>
            { row?.id > 0 && row?.name.toLowerCase() != 'repasse' &&
                <>
                    <button className={rowStatus?'paid_btn':'pending_btn'} onClick={switchExpensePaidStatus}>{rowStatus? 'Pago' : 'Pendente'}</button>
                </>
            }
            </td>
            <td>
            { row?.id > 0 && row?.name.toLowerCase() != 'repasse' &&
                <>
                    <span onClick={() => setShowEditable(prev => !prev)} className="material-symbols-outlined EDIcons">edit</span>
                    <span onClick={handleDeleteExpense} className="material-symbols-outlined EDIcons">delete</span>
                </>
            }
            </td>
        </tr>
        {showEditable && (
           <Editable getExpensesByDate={getExpensesByDate} unities={unities} row={row} setShowEditable={setShowEditable}/>
        )}
        </>
    )}

export default Item