import { useContext, useEffect, useState } from 'react'
import './styles.css'
import { DatePickerContext } from '../../contexts/datePickerCtx'
import { getPeriodFromCookies } from './handleCookies'
import { isoToDefaultInputValue } from '../../services/utils/iso_to_date_string'

export const GlobalDatePicker = () => {
    const {period, unity, setUnity, setPeriod, chosenDay_ref, chosenMonth_ref, 
        chosenQuarter_ref, chosenYear_ref, chosenInterval_final_ref, 
        chosenInterval_inicial_ref, button_ref} = useContext(DatePickerContext)
   
    const [defaultValue, setDefaultValue] = useState()

    const handleChangePeriod = (e) => {
        setPeriod(e.target.value)
    }
    const handleChangeUnity = (e) => {
        setUnity(e.target.value)
    }

    useEffect(()=>{
        const cookie = getPeriodFromCookies()
        if (cookie) {
            setPeriod(cookie.period)
            setUnity(cookie.unity)
            switch(cookie.period){
                case "by_day":
                    setDefaultValue(isoToDefaultInputValue(cookie.date))
                    break
                    
                case "by_month":
                    setDefaultValue(isoToDefaultInputValue(cookie.date).slice(0,7))
                    break
                
                case "by_quarter":
                    const parsed_date = isoToDefaultInputValue(cookie.date)
                    setDefaultValue({
                        year: parsed_date.slice(0,4),
                        quarter: parsed_date.slice(5,7)
                     })
                    break
                
                case "by_year":
                    setDefaultValue(isoToDefaultInputValue(cookie.date).slice(0,4))
                    break
                
                case "by_range":
                    const parsed_start = isoToDefaultInputValue(cookie.date)
                    const parsed_end = isoToDefaultInputValue(cookie.end_date)
                    setDefaultValue({
                        start: parsed_start.slice(0,10),
                        end: parsed_end.slice(0,10)
                    })
                    break
                
                default:
                    console.log('cookie error')
                    break
                
            }
        }
    },[])

    if (!(`/${window.location.href.split('/')[3]}`==='/home') 
        && !(`/${window.location.href.split('/')[3]}`==='/virtualvaluepage')
        && !(`/${window.location.href.split('/')[3]}`==='/clientes')
        && !(`/${window.location.href.split('/')[3]}`==='/clientpage')
        )
    {

    return (
        <div id='globalDatePicker'>
            <div className='firstSelector'>
                <div className='select'>
                    <label className='label'>Escolha o período: </label>
                    <select onChange={handleChangePeriod} value={period}>
                        <option value='by_day'>Dia</option>
                        <option value='by_month'>Mês</option>
                        <option value='by_quarter'>Trimestre</option>
                        <option value='by_year'>Ano</option>
                        <option value='by_range'>Escolha um período</option>
                    </select>
                </div>
                <div className='select'>
                    <label className='label'>Unidade: </label>
                    <select onChange={handleChangeUnity} value={unity}>
                        <option value='1'>UDA</option>
                        <option value='2'>UDF</option>
                        <option value='3'>ESCRITÓRIO</option>
                        <option value='todas'>TODAS</option>
                    </select>
                </div>
            </div>


        {period==='by_day'?(
                <div className='container'>
                    <div className='field'>
                        <label>Dia:</label>
                        <input ref={chosenDay_ref} type='date' defaultValue={defaultValue}/>   
                    </div>
                </div>
        ):period==='by_month'?(
                <div className='container'>
                    <div className='field'>
                        <label>Mês:</label>
                        <input ref={chosenMonth_ref} type='month' defaultValue={defaultValue}/>   
                    </div>
                </div>
        ):period==='by_quarter'&&defaultValue?(
                <div className='container'>
                    <div className="field">
                        <label>Trimestre: </label>
                        <select ref={chosenQuarter_ref} defaultValue={defaultValue.quarter}>
                            <option value='01'>T1</option>
                            <option value='04'>T2</option>
                            <option value='07'>T3</option>
                            <option value='10'>T4</option>
                        </select>
                    </div>
                    <div className="field">
                        <label>Ano: </label>
                        <input ref={chosenYear_ref} type="number" min="1900" max="2099" step="1" defaultValue={defaultValue.year} />
                    </div>
                </div>
        ):period==='by_year'?(
                <div className='container'>
                    <div className="field">
                        <label>Ano: </label>
                        <input ref={chosenYear_ref} type="number" min="1900" max="2099" step="1" defaultValue={defaultValue} />
                    </div>
                </div>
        ):period==='by_range'?(
                <div className='container'>
                    <div className="field">
                        <label>Data inicial:</label>
                        <input ref={chosenInterval_inicial_ref} type='date' defaultValue={defaultValue.start}/>   
                    </div>
                    <div className="field">
                        <label>Data final:</label>
                        <input ref={chosenInterval_final_ref} type='date' defaultValue={defaultValue.end}/>   
                    </div>
                </div>
        ):(<></>)}
            <button ref={button_ref}>Buscar</button>

        </div>

    )
}
}