import { useEffect, useRef, useState } from 'react';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { getAllUnities, getAllCollaborators, invalidadeVirtualValue, getRealValues, invalidadeRealValue, getAllCustomers } from '../../../services/axios';
import AddRealValue from '../addRealValue/index.jsx';
import './styles.css'
import Button from '../../../components/button';
import formatMoney from '../../../services/utils/formatMoney';
import { MoneyInput } from '../../../components/moneyInput';
import { isoToDateStr } from '../../../services/utils/iso_to_date_string';

import EditableClient from "../../clientes/tableRow/editable"
import { EditableRealValue, EditableVirtualValue } from './editable/index.jsx';

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: 600,
      height: 500,
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#1d3562',
      color: 'White'
    },
  };

const ValuesModal = ({getValues,showNotification, value, defaultModalState=false, allTags=[]}) => {
    const navigation = useNavigate()
    const [editableValue, setEditableValue] = useState(value) 
    const [modalIsOpen, setIsOpen] = useState(defaultModalState);
    const [showDeleteForm, setShowDeleteForm] = useState (-1)
    const [showEditForm, setShowEditForm] = useState (false)
    const [showEditable, setShowEditable] = useState(false)
    const [showVirtualEditable, setShowVirtualEditable] = useState(false)
    const [money,setMoney] = useState((value.value *100).toString())
    const deleteRef = useRef()
    const editRef = useRef()
    const [unities, setUnities] = useState([])
    const [collaborators, setCollaborators] = useState()

    const [realValues, setRealValues] = useState()

    useEffect(() => {
      setEditableValue(value)
    },[value])
    
    const findUnities = async () => {
      const response = await getAllUnities()
      setUnities(response)
    }

    const findCollaborators = async() => {
      const response = await getAllCollaborators()
      setCollaborators(response)
    }

    const findRealValues = async () => {
      const response = await getRealValues(value.id)
      if (response.length <= 0){
        setRealValues(response)
        return
        showNotification({type: 'warning', text: "Sem pagamentos para mostrar", })
        return
      }
      setRealValues(response)
    }

    const toggleEditForm = (e) => {
      e.preventDefault()
      setShowDeleteForm(false)
      const inputs = document.querySelectorAll('.editableInput')
      setShowEditForm((prev)=> !prev)
      inputs.forEach((input) => input.removeAttribute('disabled'))
  }

    const toggleDeleteForm = (e) => {
      e.preventDefault()
      setShowEditForm(false)
      setShowDeleteForm((prev) => !prev)
    }
    
    const deleteUser = async(e) => {
      e.preventDefault()
      if(deleteRef.current.value.toUpperCase() === 'DELETAR'){
        try {
          const response = await invalidadeVirtualValue(value.id)
          if (response.status === 200){
            await getValues()
            showNotification({type: 'ok', text: "Valor deletado!" })
          }
        } catch (error) {
          showNotification({type: 'error', text: "Erro ao deletar valor" })
        }
      }else{
        showNotification({type: 'error', text: "O texto digitado não confere." })
      }
    }

    function expand () {
      realValues?setRealValues():findRealValues()
    }

    const deleteItem = async() => {
      if ( window.confirm(`Tem certeza que deseja DELETAR a receita de ID ${value.id} e Valor ${value.value}?`) ) {
        await invalidadeVirtualValue(value.id)
        showNotification({text: 'Receita deletada!', type:'ok'})
        getValues()
        return
    }

    showNotification({text: 'Receita não deletada.', type:'error'})
    }

    const deleteRvalue = (r_value_id) => {
      if ( window.confirm(`Tem certeza que deseja DELETAR o pagamento de ID ${r_value_id}?`) ) {
        invalidadeRealValue(r_value_id)
          .then(() => {
            showNotification({text: 'Pagamento deletado!', type: 'ok'})
            findRealValues()
          })
          .catch(error => { 
              console.log(error)
              showNotification({text: 'Erro ao tentar apagar pagamento.', type: 'error'})
            })
            return
      }
      showNotification({text: 'Operação cancelada.', type: 'warning'})
    }
    
    function openModal() {
      findUnities()
      findCollaborators()
      setIsOpen(true);
    }
    const goToExpandedPage = () => {
      navigation('../virtualvaluepage', {state: {data: props}})
    }
    
    function closeModal() {
      setShowDeleteForm(false)
      setShowEditForm(false)
      setIsOpen(false);
      setEditableValue(value)
    }
    const props = {value}
    return(
      <tbody id='tbody' className="tbody">
                    <tr className='tdHover valueRow despesasRow'>
                        <td onClick={expand}  className='value tdName tdHover'>{value.payer_name}</td>
                        <td onClick={expand}  className='value tdHover tdTags'>
                          {value.tags.map((tag, index) => (
                          <h1 className='tagContainer' key={index} style={{backgroundColor:tag.color}}>{tag.name}</h1>
                          ))}
                        </td>
                        <td onClick={expand} className='value tdHover'>
                            {isoToDateStr(value.reference_date)}
                        </td>
                        <td onClick={expand} className='value tdHover'>
                            {isoToDateStr(value.payment_date)}
                        </td>
                        <td onClick={expand} className='value tdHover'>
                            {value.description || '-'}
                        </td>
                        <td onClick={expand} className='value tdHover'>
                            {formatMoney(value.value)}
                        </td>
                        <td onClick={expand} className='value tdHover'>
                            {formatMoney(value.value_paid)}
                        </td>
                        <td onClick={expand} className='value tdHover'>
                            {value.percentage_paid}%
                        </td>
                        <td onClick={expand} className={value.paid?"value tdHover pago":"value tdHover pendente"}>
                            {value.paid?"Pago":"Pendente"}
                        </td>
                        <td className='tooltips tooltip1'>Expandir pagamentos</td>
                    </tr>
                        <tr onClick={goToExpandedPage} className='editIcon'>
                          <td className="material-symbols-outlined openInFullIcon">open_in_full</td>
                          <td className='tooltips tooltip2'>Abrir em página inteira</td>
                        </tr>
                        <tr onClick={openModal} className='detailIcon'>
                          <td className="material-symbols-outlined openInFullIcon">add</td>
                          <td className='tooltips tooltip3'>Adicionar Pagamento</td>
                        </tr>
                        <tr onClick={() => setShowVirtualEditable(showVirtualEditable === value.id ? null : value.id)} className='editIcon realEditIcon'>
                          <td className="material-symbols-outlined openInFullIcon">edit</td>
                          <td className='tooltips tooltip4'>Editar Pagamento</td>
                        </tr>
                        <tr onClick={deleteItem} className='editIcon deleteIcon'>
                          <td className="material-symbols-outlined openInFullIcon">delete</td>
                          <td className='tooltips tooltip5'>Deletar Pagamento</td>
                        </tr>
                        
                        <tr className='paymentsExpandedBody'>{showVirtualEditable == value.id && <EditableVirtualValue getValues={getValues} showNotification={showNotification} value={value} setShowEditable={setShowVirtualEditable} allTags={allTags}/>}</tr>
                        
                        {realValues?(
                          <>   
                            <tr>
                              <th className='paymentsSubtitles'>ID</th>
                              <th className='paymentsSubtitles'>Valor</th>
                              <th className='paymentsSubtitles'>Pago em</th>
                              <th className='paymentsSubtitles'>Observações</th>
                              <th className='paymentsSubtitles'></th>
                              <th className='paymentsSubtitles'></th>
                            </tr>   
                          </>
                        ):null}
                        {realValues?.map((r_value) =>
                        <>
                          <tr key={r_value.id+r_value.value} className='paymentsExpandedBody'>
                            <td className='value'>{r_value.id}</td>
                            <td className='value'>{formatMoney(r_value.value)}</td>
                            <td className='value'>{isoToDateStr(r_value.payment_date)}</td>
                            <td className='value'>{r_value.description || '-'}</td>
                            <td className='value'></td>
                            <td className='value'></td>
                            <td  onClick={() => setShowEditable(showEditable === r_value.id ? null : r_value.id)}  className='value deleteRValue'>
                              <span className='material-symbols-outlined'>edit</span>
                            </td>
                            <td  onClick={() => deleteRvalue(r_value.id)}  className='value deleteRValue'>
                              <span className='material-symbols-outlined'>delete</span>
                            </td>
                          </tr>
                          <tr className='paymentsExpandedBody'>{showEditable == r_value.id && <EditableRealValue getValues={getValues} showNotification={showNotification} setRealValues={setRealValues} value={r_value} parent_value={value} setShowEditable={setShowEditable}/>}</tr>
                          </>
                        )}

        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="VirtualValue"
        ariaHideApp={false}
        >
          <form id='modal'>
            <AddRealValue virtual={value}/>
                <div className='allFields'>
                    
                    <div className='modalField origem'>
                      <label className='label'>Origem: </label>
                      <input disabled defaultValue={editableValue.payer_name}>
                      </input>
                      
                    </div>
                    <div className='modalField descricao'>
                    <label className='label'>Descrição: </label>
                    <textarea className='editableInput' name='description' type='text' 
                    onChange={(e) => {
                        const {...newValue} = editableValue
                        newValue.description = e.target.value
                        setEditableValue(newValue)
                    }} 
                    disabled value={editableValue.description}/>
                    </div>
                    <div className='modalField semestre'>
                    <label className='label'>Data de referência: </label>
                    <input 
                    name='quarter' type='text' disabled value={isoToDateStr(editableValue.reference_date)}
                    onChange={(e) => {
                        const {...newValue} = editableValue
                        newValue.quarter_id = e.target.value
                        setEditableValue(newValue)
                    }} 
                    />
                    </div>
                    <div className='modalField valor'>
                      <label className='label'>Valor da Receita: </label>
                        <MoneyInput disabled value={formatMoney(money/100)} setValue={setMoney}/>
                    </div>
                    <div className='modalField valor'>
                      <label className='label'>Valor Pago: </label>
                        <input disabled type='text' value={formatMoney(editableValue.value_paid)}/>
                    </div>
                    <div className='modalField parcelas'>
                    <label className='label'>Número de parcelas: </label>
                    <input className='editableInput' name='stallments' type='number' disabled value={editableValue.stallments}
                                    onChange={(e) => {
                                    const {...newValue} = editableValue
                                    newValue.stallments = parseInt(e.target.value)
                                    setEditableValue(newValue)
                                    }} 
                    />
                    </div>
                    <div className='modalField datadepagamento'>
                    <label className='label'>Data de pagamento: </label>
                    <input name='payment_date' type='date' className='dateInput editableInput' disabled defaultValue={value.payment_date.slice(0,10)}
                                     onChange={(e) => {
                                      const {...newValue} = editableValue
                                      newValue.payment_date = e.target.value
                                      setEditableValue(newValue)
                                      }}
                    />
                    </div>
                    <div className='modalField pagoem'>
                    <label className='label'>Pago em: </label>
                    <input name='paid_at' type='text' disabled value={isoToDateStr(editableValue.paid_at) || 'Pendente'}
                                    onChange={(e) => {
                                    const {...newValue} = editableValue
                                    newValue.paid_at = e.target.value
                                    setEditableValue(newValue)
                                    }} 
                    />
                    </div>
                    <div className='modalField equipe'>
                    <label className='label'>Segmento responsável: </label>
                    <select disabled className='editableInput' value={editableValue.unity_id} onChange={(e) => {
                        const {...newValue} = editableValue
                        newValue.unity_id = parseInt(e.target.value)
                        setEditableValue(newValue)
                      }} >
                    {unities?.map((unity, index) => 
                      <option key={index} value={unity.id}>{unity.name}</option>
                    )}
                  </select>
                    </div>
                    <div className='modalField colaborador'>
                    <label className='label'>Colaborador responsável: </label>
                    <select name='collaborator' className='editableInput' disabled value={editableValue.collaborator_id}
                                    onChange={(e) => {
                                      const {...newValue} = editableValue
                                      newValue.collaborator_id = parseInt(e.target.value)
                                      setEditableValue(newValue)
                                    }} 
                    >{collaborators?.map((collab,index) => (
                      <option key={index} value={collab.id}>{collab.name}</option>
                    ))}</select>
                    </div>

                </div>

                <div className='modifyBtnsContainer'>
                <Button externo={true} onclick={toggleEditForm} classname='modifyBtns' title='Editar Progressão'/>
                <Button externo onclick={toggleDeleteForm} classname='modifyBtns'title='Deletar Valor'/>
              </div>
              {showEditForm?(
              <div className=''>
                {/* <div className='deleteLabel'>
                  <label>Data da alteração: </label>
                  <input ref={modifyDateRef} type='date' defaultValue={today} />
                </div> */}
                <label className='deleteLabel'>Deseja realmente <span className='highlight'>EDITAR</span> o Colaborador atual no banco de dados?</label><br/>
                <label className='deleteLabel'>Digite <span className='highlight'>EDITAR</span> para prosseguir: </label>
                <input type='text' ref={editRef}></input>
                <Button externo={true} onclick={console.log()} title='Confirmar' classname='submitBtn'/>
              </div>
              )
              :''
            }
            {showDeleteForm?(
            <div className=''>
              <label className='deleteLabel'>Deseja realmente <span className='highlight'>DELETAR</span> o Valor atual do banco de dados?</label><br/>
              <label className='deleteLabel'>Digite <span className='highlight'>DELETAR</span> para prosseguir: </label>
              <input type='text' ref={deleteRef}></input>
              <Button externo classname='submitBtn' onclick={deleteUser} title='Confirmar'/>
            </div>
            )
            :''
          }
          <div className='closeModalBtn' onClick={closeModal}>Voltar</div>
          </form>
      </Modal>
        </tbody>
    )
}

export default ValuesModal





