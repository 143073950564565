import { useRef, useState } from "react";
import styles from './editablepayment.module.css'
import formatMoney from '../../../../services/utils/formatMoney';
import { updateRealValue, getRealValues, updateVirtualValue, getVirtualValues } from "../../../../services/axios";
import { MoneyInput } from "../../../../components/moneyInput";
import { unformatMoney } from "../../../../services/utils/formatMoney";
import { isoToDefaultInputValue } from "../../../../services/utils/iso_to_date_string";


function EditRealValue({showNotification, setShowEditable, getValues, setRealValues, value, parent_value}) {
    const [money,setMoney] = useState(formatMoney(value.value))
    const payment_date_ref = useRef()
    const description_ref = useRef()
    const stallments_ref = useRef()

    const cancelUpdate = () => {
        setShowEditable(false)
    }

    const updateValue = async (e) => {
        e.preventDefault()
        console.log(payment_date_ref.current.value)
        const data = {
            id: value.id,
            value: unformatMoney(money) || null,
            description: description_ref.current.value || null,
            payment_date: payment_date_ref.current.value ? new Date(payment_date_ref.current.value.split('-')) : null,
            stallment: stallments_ref.current.value || null,
        }

        try {
            if ( window.confirm(`Tem certeza que deseja ATUALIZAR a receita de ID ${value.id} e Valor ${value.value}?`) ){
                const response = await updateRealValue(data)
                showNotification({text: 'Valor Atualizado!', type: 'ok'})
                console.log('atualizou')
                const resp2 = await getRealValues(parent_value.id)
                setRealValues(resp2)
                getValues()
                setShowEditable(null)
            }
        } catch(error) {
            console.log("🚀 ~ error:", error)
            showNotification({text: 'Erro ao atualizar.', type: 'error'})
        }
    }

    return (
        <>
            {Notification}
            <td></td>
            <td className={styles.field}>
                <label>Valor: </label>
                <MoneyInput value={money} setValue={setMoney} />
            </td>
            <td className={styles.field}>
                <label>Data de pagamento: </label>
                <input ref={payment_date_ref} type="date" defaultValue={isoToDefaultInputValue(value.payment_date)} />
            </td>
            <td className={styles.field}>
                <label>Observações: </label>
                <textarea ref={description_ref} type="text" defaultValue={value.description} />
            </td>
            <td className={styles.buttonsField}>
                <input onClick={() => setShowEditable(false) } type='button' value='Cancelar'/>
                <input onClick={updateValue} type='button' value='Atualizar'/>
            </td>
        </>
    )
}

export default EditRealValue