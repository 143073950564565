import { useEffect, useState } from "react";
import ValuesModal from "../valuesModal";
import './styles.css'
import { getAllCustomers } from "../../../services/axios";
import styles from '../../payments/table/paymentsTable.module.css'
import formatMoney from "../../../services/utils/formatMoney";

const ValuesTable = ({getValues, showNotification, unity, toggleOrder, orderBy, isReverse, allTags = []}) => {
    const hasValues = unity.values.length > 0
    const [total, setTotal] = useState()
    const [totalPaid, setTotalPaid] = useState()
    const [totalPercent, setTotalPercent] = useState()
    const [totalPaidBalance, setTotalPaidBalance] = useState()

    useEffect(() => {
        let sum = 0
        unity.values?.map((item) => sum +=item.value)
        setTotal(sum)

        let sum2 = 0
        unity.values?.map((item) => sum2 +=item.value_paid)
        setTotalPaid(sum2)

        setTotalPercent(parseFloat(`${sum2/sum*100}`).toFixed(2))
    
        sum = 0
        unity.values?.map((item) => item.paid ? sum += 1 : sum += 0)
        setTotalPaidBalance(parseFloat(`${sum/unity.values.length*100}`).toFixed(2))

    }, [unity.values])

    if (hasValues) {
        return( 
            <>
                <h3>{unity.unity_name}</h3>
                <table id='table' className={`${styles.table} table`}>
                <thead>
                        <tr className='typeRow'>
                            <th onClick={() => toggleOrder('payer_name')}  className='tdName labels'>{orderBy==='payer_name'?isReverse?'↓ Origem':'↑ Origem':'Origem'}</th>
                            <th className='tdName labels tagLabel'>Marcadores</th>
                            <th onClick={() => toggleOrder('reference_date')} className='value labels'>{orderBy==='reference_date'?isReverse?'↓ Data de referência':'↑ Data de referência':'Data de referência'}</th>
                            <th onClick={() => toggleOrder('payment_date')} className='value labels'>{orderBy==='payment_date'?isReverse?'↓ Data de pagamento':'↑ Data de pagamento':'Data de pagamento'}</th>
                            <th onClick={() => toggleOrder('description')} className='value labels'>{orderBy==='description'?isReverse?'↓ Observações':'↑ Observações':'Observações'}</th>
                            <th onClick={() => toggleOrder('value')} className='value labels'>{orderBy==='value'?isReverse?'↓ Valor':'↑ Valor':'Valor'}</th>
                            <th onClick={() => toggleOrder('value_paid')} className='value labels'>{orderBy==='value_paid'?isReverse?'↓ Valor Pago':'↑ Valor Pago':'Valor Pago'}</th>
                            <th onClick={() => toggleOrder('percentage_paid')} className='value labels'>{orderBy==='percentage_paid'?isReverse?'↓ % Paga':'↑ % Paga':'% Paga'}</th>
                            <th onClick={() => toggleOrder('paid')} className='value labels'>{orderBy==='paid'?isReverse?'↓ Status':'↑ Status':'Status'}</th>
                        </tr>
                </thead>
                            {unity.values?.length >0 ? unity.values.map((row, index)=>(
                                <ValuesModal key={row.id+'-'+index} showNotification={showNotification} getValues={getValues} value={row} index={index} allTags={allTags}/>
                            )):null}
                            <tr className='valueRow despesasRow'>
                                <td className='value tdTotal'>
                                TOTAL 
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td className='value tdTotal'>
                                {formatMoney(total)}
                                </td>
                                <td className='value tdTotal'>
                                {formatMoney(totalPaid)}
                                </td>
                                <td>{totalPercent}%</td>
                                <td>
                                <div style={{width:`${totalPaidBalance}%`, backgroundColor:"rgba(133, 253, 122, 0.732)"}}>
                                    {totalPaidBalance}%
                                </div>
                                </td>
                            </tr>
                </table>
                </>
        )
  }
    }
    
    export default ValuesTable