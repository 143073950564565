import { createContext, useEffect, useRef, useState } from "react"
import { slugToTx } from "../services/utils/dateSlug"
import { StoragePeriod } from "../components/globalDatePicker/handleCookies"

export const DatePickerContext = createContext()

export const DatePickerProvider = ({children}) => {
    
    const [period, setPeriod] = useState()
    const [unity, setUnity] = useState()
    const chosenDay_ref = useRef()
    const chosenMonth_ref = useRef()
    const chosenQuarter_ref = useRef()
    const chosenYear_ref = useRef()
    const chosenInterval_inicial_ref = useRef()
    const chosenInterval_final_ref = useRef()
    const button_ref = useRef()

    const handleSubmit = (fun) => {
        const {date, end_date} = formatCurrentDate()
        const data = {
            period,
            date,
            end_date,
            unity
        }
        StoragePeriod({data})
        fun();
    }
    const formatCurrentDate = () => {
        let title
        let date
        let end_date = ''
        let str = ''
        let month = ''
        let year = ''
        try {
            switch(period){
                case 'by_day':
                    str = chosenDay_ref.current.value
                    if(str){
                        str = str.replaceAll('-', '/')
                    }
                    title=str.split('/').reverse().join('/')
                    date = (new Date(str)).toISOString()
                    break
                case 'by_month':
                    str = chosenMonth_ref.current.value
                    if(str){
                        str = str.replaceAll('-', '/')
                    }
                    title=str.split('/').reverse().join('/')
                    date = (new Date (`${str}/01`).toISOString())
                    break
                case 'by_quarter':
                    month = chosenQuarter_ref?.current?.value
                    year = chosenYear_ref?.current?.value
                    date = new Date(`${year}/${month}/01`)
                    end_date = new Date(`${year}/${month}/01`)
                    end_date = (new Date(end_date.setMonth(end_date.getMonth()+2))).toISOString()
                    date = date.toISOString()
                    title = `${slugToTx(month)}/${year}`
                    break
                case 'by_year':
                    year = chosenYear_ref.current.value
                    date = (new Date(`${year}/01/01`)).toISOString()   
                    end_date = (new Date(`${year}/12/31`)).toISOString()
                    title=year
                    break
                case 'by_range':
                    const old_date =chosenInterval_inicial_ref.current.value.replaceAll('-', '/')
                    const old_end_date = chosenInterval_final_ref.current.value.replaceAll('-', '/')
                    date = (new Date(old_date)).toISOString()
                    end_date = (new Date(old_end_date)).toISOString()
                    title=`${old_date.split('/').reverse().join('/')} - ${old_end_date.split('/').reverse().join('/')}` 
                    break
                default:
                    console.log('switch/case error -> ', period)     
            }
        } catch (error) {
            console.log('erro ao processar data. -> ', date, end_date)
        }
        return {date, end_date, title}
    }

    return(
        <DatePickerContext.Provider 
        value={{formatCurrentDate, unity, setUnity, period, setPeriod, chosenDay_ref, chosenMonth_ref, chosenQuarter_ref,
         chosenYear_ref, chosenInterval_inicial_ref, chosenInterval_final_ref, handleSubmit, button_ref}}>
            
            {children}
        </DatePickerContext.Provider>
    )
}