import { useContext, useEffect, useState } from 'react';
import ValuesTable from './valuesTable';
import ValuesForm from './valuesForm';
import { getAllCustomers, getAllUnities, getTags, virtualValuesByDate } from '../../services/axios';
import PageTitle from '../../components/pageTitle';
import { DatePickerContext } from '../../contexts/datePickerCtx';
import LoadContainer from '../../components/loading';
import styles from '../payments/payments.module.css'
import { useNotification } from '../../components/useNotification';

const pattern = [
    {unity_name: 'UDA', values: []},
    {unity_name: 'UDF', values: []},
    {unity_name: 'ESCRITÓRIO', values: []},
]
const Valores = () => {
    const {Notification, showNotification} = useNotification()
    const {period, button_ref, formatCurrentDate, handleSubmit, unity} = useContext(DatePickerContext)
    const [virtual, setVirtual] = useState(pattern)
    const [isReverse, setIsReverse] = useState(false)
    const [orderBy, setOrderBy] = useState('payer_name')
    const [currentPage, setCurrentPage] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const [unities, setUnities] = useState([])
    const [allTags, setAllTags] = useState([]);

    const toggleOrder = (str) => {
        setCurrentPage(0)

        if (orderBy===str){
            if(isReverse){
                setOrderBy(str)// ''
                setIsReverse(false)
                return
            }else{
                setIsReverse(true)
                return
            }
        }else{
            setOrderBy(str)
            return
        }
    }

    const getVirtualValues = async (reset = false) => {
        setIsLoading(true)
        
        const {date, end_date} = formatCurrentDate()
        if(!date){
            console.log('erro ao processar data. -> ', date)
            setIsLoading(false)
            return
        }
        const response = await virtualValuesByDate({
            date: date,
            end_date: end_date,
            currentPage: 0,
            // currentPage: reset?0:currentPage,
            by_month: period==='by_month',
            by_quarter: period==='by_quarter',
            orderBy: orderBy,
            isReverse: isReverse,
        })

        let filtered = response
        if(unity !== 'todas'){
            filtered = response.filter((item) => item.unity_id===parseInt(unity))
        }

        const uda = filtered.filter((item) => item.unity_id===1)
        const udf = filtered.filter((item) => item.unity_id===2)
        const esc = filtered.filter((item) => item.unity_id===3)
       
        
        setVirtual((prev) => {
            const result = prev
    
            result.find((item) => item.unity_name === 'UDA').values = uda
            result.find((item) => item.unity_name === 'UDF').values = udf
            result.find((item) => item.unity_name === 'ESCRITÓRIO').values = esc
            return result
        })
        setIsLoading(false)
    }

    const reset = async () => {
        setVirtual(pattern)
        const reset = true
        await getVirtualValues(reset)
        setCurrentPage(0)
    }

    const getData = async () => {
        const tags = await getTags()
        setAllTags(tags)
    }

    useEffect(()=>{
        setCurrentPage(0)
        setVirtual(pattern)
        button_ref.current.onclick = () => handleSubmit(reset)
	},[period, unity])

    useEffect(()=>{
        if (currentPage===0){
            return
        }
        getVirtualValues()
    },[currentPage])

    useEffect(()=>{
        const reset = true
        getVirtualValues(reset)
    },[orderBy, isReverse])

    useEffect(() => {
        if (unity === undefined || (virtual[0].values.length > 0 || virtual[1].values.length > 0 || virtual[2].values.length > 0)) {
            return
        }
        getVirtualValues()
    }, [unity])

    useEffect(() => {
        getData()
    }, [])

    // useEffect(()=> {
    //     const observer = new IntersectionObserver ((entries) => {
    //         if (entries.some((entry) => entry.isIntersecting)) {
    //                 console.log('showing..')
    //                 console.log('current -> ', currentPage)
    //                 setCurrentPage((prev) => prev + 1)
    //         }
    //     })
    //     observer.observe(document.querySelector('#ward'))
    //     return () => observer.disconnect()
    // }, [])

    return(
        <div id='valores' className='pageContainer'>
            {isLoading && <LoadContainer/>}
            {Notification}
            <PageTitle title={'Receitas'}/>
            <ValuesForm getVValues={getVirtualValues} allTags={allTags}/>
            <div className={styles.tableContainer}>
                        <h2>Receitas (valores virtuais)</h2>
                        {virtual?.map((unity) => (
                            <ValuesTable showNotification={showNotification} getValues={() => getVirtualValues(true)} key={unity.name} orderBy={orderBy} isReverse={isReverse} toggleOrder={toggleOrder} unity={unity} setValues={setVirtual} allTags={allTags}/>
                        ))}
                        <div id='ward'></div>	
			</div>
        </div>
    )
}

export default Valores