import Payment from "./payment"
import styles from '../paymentsTable.module.css'

const Collab = ({collab, getData}) => {
    if (collab.payments.length > 0) {
        return(
            <tbody>
                <tr>
                    <th className={styles.thName}>{collab.name}</th>
                    <th className={styles.thName}></th>
                    <th className={styles.thName}></th>
                    <th className={styles.thName}></th>
                    <th className={styles.thName}></th>
                    <th className={styles.thName}></th>
                    <th className={styles.thName}></th>
                </tr>
                {collab.payments?.map((payment) => (
                    <Payment key={payment.id} payment={payment} getData={getData} />
                ))}
            </tbody>
            )
    }
}

export default Collab