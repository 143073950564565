import { useState } from 'react'
import formatMoney from '../../../../services/utils/formatMoney'
import styles from './paymentSection.module.css'
import AddRealValue from '../../../valores/addRealValue'
import { invalidadeRealValue, getRealValues, getVirtualValues } from '../../../../services/axios'
import { EditableRealValue } from '../../../valores/valuesModal/editable'
import EditRealValue from './editable'

const PaymentSection = ({getClientValues, isPendency = false, paid, values, title, showNotification}) => {
    const [openPaymentForm, setOpenPaymentForm] = useState(null)
    const [openEditValueForm, setOpenEditValueForm] = useState(null)
    const [realValues, setRealValues] = useState(false)
    const [showEditable, setShowEditable] = useState(false)
    const handleOpenAddPayment = ({value}) => {
        setOpenPaymentForm(value)
    }

    const handleOpenEditValue = ({value}) => {
        if (openEditValueForm && openEditValueForm.id === value.id) {
            setOpenEditValueForm(null)
        } else {
            setOpenEditValueForm(value)
        }            
    }

    const findRealValues = async (value) => {
        console.log(realValues)
        if (realValues && realValues[0].vvalue_id === value.id) {
            setRealValues(false)
            return
        }
        const response = await getRealValues(parseInt(value.id))
        console.log(response)
        if (response.length <= 0){
          setRealValues(false)
          return
        }
        setRealValues(response)
      }

    const deleteRvalue = (r_value_id) => {
        if ( window.confirm(`Tem certeza que deseja DELETAR o pagamento de ID ${r_value_id}?`) ) {
          invalidadeRealValue(r_value_id)
            .then(() => {
            //   showNotification({text: 'Pagamento deletado!', type: 'ok'})
              findRealValues()
            })
            .catch(error => { 
                console.log(error)
                // showNotification({text: 'Erro ao tentar apagar pagamento.', type: 'error'})
              })
              return
        }
        // showNotification({text: 'Operação cancelada.', type: 'warning'})
      }

    return(
        <>
        <div className={styles.container}>
            <h4 className={styles.title}>{title}</h4>
                
            {values?.length > 0 && 
            <div className={styles.valuesContainer}>
            {values?.map(((value) => (
                <>
                <div key={value.id} className={styles.value}>
                            <div className={styles.field}>
                            <div>
                                <label className={styles.label}>{isPendency?'Valor Total':'Valor' }: </label>
                                <span className={styles.span}>{formatMoney(value.value)}</span>
                            </div>
                            {isPendency && 
                                <div>
                                    <label className={styles.label}>Pendente: </label>
                                    <span className={styles.span}>{formatMoney(value.value-value.value_paid)}</span>
                                </div>
                            }
                            <div>
                                <label className={styles.label}>Data {paid?'de':'para o'} pagamento:  </label>
                                <span className={styles.span}>{value.payment_date?.slice(0,10).split('-').reverse().join('/')}</span>
                            </div>
                            <div>
                                <label className={styles.label}>Data de referência:  </label>
                                <span className={styles.span}>{value.reference_date?.slice(0,10).split('-').reverse().join('/')}</span>
                            </div>
                            <div>
                                <label className={styles.label}>Observações: </label>
                                <span className={styles.span}>{value.description}</span>
                            </div>
                        </div>
                        <div className={styles.moneyBtnContainer}>

                            <div onClick={() => findRealValues(value)} className={styles.moneyBtn}>
                                <span className={`material-symbols-outlined`}>edit</span>
                                <span className={styles.tooltip}>Gerenciar receita</span>
                            </div>
                            <div className={styles.moneyBtn}>
                                 <span  onClick={() => handleOpenAddPayment({value})} className={`material-symbols-outlined`}>paid</span>
                                 <span onClick={() => handleOpenAddPayment({value})}>+</span>
                                 <span onClick={() => handleOpenAddPayment({value})} className={styles.tooltip}>adicionar pagamento</span>
                                 
                                 {openPaymentForm?.id===value.id && (
                                     <>
                                    <div className={styles.paymentFormContainer}>
                                        <AddRealValue setOpenPaymentForm={setOpenPaymentForm} getClientValues={getClientValues} virtual={openPaymentForm}/>
                                    </div>
                                    </>
                                 )}
                            </div>
                        </div>
                        <table>
                                {realValues && realValues[0].vvalue_id === value.id?(
                                <>   
                                    <tr>
                                    <th className='paymentsSubtitles'>ID</th>
                                    <th className='paymentsSubtitles'>Valor</th>
                                    <th className='paymentsSubtitles'>Pago em</th>
                                    <th className='paymentsSubtitles'>Observações</th>
                                    <th className='paymentsSubtitles'></th>
                                    <th className='paymentsSubtitles'></th>
                                    </tr>   
                                </>
                                ):null}
                                {realValues && realValues[0].vvalue_id === value.id && realValues?.map((r_value) =>
                                <>
                                <tr key={r_value.id+r_value.value} className='paymentsExpandedBody'>
                                    <td className='value'>{r_value.id}</td>
                                    <td className='value'>{formatMoney(r_value.value)}</td>
                                    <td className='value'>{r_value.payment_date.slice(0, 10).split('-').reverse().join('/')}</td>
                                    <td className='value'>{r_value.description || '-'}</td>
                                    <td className='value'></td>
                                    <td className='value'></td>
                                    <td  onClick={() => setShowEditable(showEditable === r_value.id ? null : r_value.id)}  className='value deleteRValue'>
                                    <span className='material-symbols-outlined'>edit</span>
                                    </td>
                                    <td  onClick={() => deleteRvalue(r_value.id)}  className='value deleteRValue'>
                                    <span className='material-symbols-outlined'>delete</span>
                                    </td>
                                </tr>
                                <tr className='paymentsExpandedBody editableRow'>{showEditable == r_value.id && <EditRealValue getValues={getVirtualValues} showNotification={showNotification} setRealValues={setRealValues} value={r_value} parent_value={value} setShowEditable={setShowEditable}/>}</tr>
                                </>
                                )}
                        </table>
                </div>
                                {openEditValueForm?.id===value.id && (
                                <>
                                // get all paid values of the vvalue
                                <div className={styles.value}>
                                    <AddRealValue setOpenEditValueForm={setOpenEditValueForm} getClientValues={getClientValues} virtual={openEditValueForm}/>
                                </div>
                                </>
                                )}
                </>
            )))}
            </div>}
            {openPaymentForm &&<div onClick={() => setOpenPaymentForm(null)} className={styles.closeForm}></div>}
      </div>
    </>
    )
}

export default PaymentSection