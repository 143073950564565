import { useContext, useEffect, useState } from 'react'
import './styles.css'
import Cookies from 'js-cookie'
import icon from '../../../images/icon.png'
import { AuthContext } from '../../../contexts/auth'

const UserCard = () => {
    const {logout} = useContext(AuthContext)
    const [thisUser, setThisUser] = useState({})
    const [show, setShow] = useState(false)

    useEffect(() => {
        const c_user = Cookies.get('user')
        if (c_user){
            setThisUser(JSON.parse(c_user))
        }
    }, [])
        return (
        <div className='all_container'>
            <div onClick={() => setShow(prev => !prev)} id='usercard'>
                <img src={thisUser.picture?thisUser.picture:icon}/>
                <span className='name'>{thisUser?thisUser.name:''}</span>
            </div>
            <div className={show?'hidden_menu show':'hidden_menu'}>
                <a onClick={logout} className='logout_button'>Sair</a>
            </div>
        </div>
        )
}

export default UserCard