import { useState } from 'react'
import formatMoney from '../../../../services/utils/formatMoney'
import styles from '../tableRow.module.css'
import AddRealValue from '../../../valores/addRealValue'
import CloseForm from '../../../../components/closeForm'
import Editable from './editable'
import { invalidadeVirtualValue } from '../../../../services/axios'
import { isoToDateStr } from '../../../../services/utils/iso_to_date_string';

const PaymentField = ( {showNotification, getData, getClients, payment, showEditable, setShowEditable} ) => {
    const [showForm, setShowForm] = useState(false)
    // const [showEditable, setShowEditable] = useState(false)

    const handleDelete = async () => {
        if ( window.confirm(`Tem certeza que deseja DELETAR a receita de ID ${payment.id}?`) ) {
            await invalidadeVirtualValue(payment.id)
            showNotification({text: 'Receita deletada!', type:'ok'})
            getData()
            return
        }

        showNotification({text: 'Receita não deletada.', type:'error'})
    }

    return(
        <>
                {/* <td className={styles.idField}>
                        {payment.id}
                </td> */}
                <td >
                    <span className={`${styles.expandedItem}`}>
                        {` ${formatMoney(payment.value)}`}
                    </span>
                </td>
                <td>
                    <span className={styles.expandedItem}>
                        {payment.stallments===0?'À Vista':payment.stallments}
                    </span>
                </td>
                <td>
                    <span className={styles.expandedItem}>
                        {` ${payment.description || '-'}`}
                    </span>
                </td>
                <td>
                    <span className={styles.expandedItem}>
                        {isoToDateStr(payment.reference_date)}
                    </span>
                </td>
                <td>
                    <span className={styles.expandedItem}>
                        {` ${formatMoney(payment.value - payment.value_paid)}
                        (${payment.percentage_paid}% pago)`}
                    </span>
                </td>
                {/* <td>
                    <span className={styles.expandedItem}>
                        {isoToDateStr(payment.payment_date)}
                    </span>
                </td> */}

                <span onClick={() => setShowForm(true)} className={`${styles.addIcon} ${styles.icon} material-symbols-outlined`}>add</span>
                <span onClick={() => setShowEditable(showEditable == payment.id ? null : payment.id)} className={`${styles.editIcon} ${styles.icon} material-symbols-outlined`}>edit</span>
                <span onClick={handleDelete} className={`${styles.deleteIcon} ${styles.icon} material-symbols-outlined`}>delete</span>
                
                {showForm && (
                    <CloseForm onclick={() => setShowForm(false)} />
                )}
                    {showForm && (
                        <>
                        <div className={styles.paymentFormContainer}>
                            <AddRealValue getData={getData} virtual={payment} setOpenPaymentForm={setShowForm}  getClientValues={getClients} />
                        </div>
                        </>
                    )}
            {/* {showEditable && <Editable showNotification={showNotification} getData={getData} setShowEditable={setShowEditable} payment={payment}/>} */}
        </>
    )
}

export default PaymentField