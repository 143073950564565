import { deletePayment, markAsPaid } from '../../../../../services/axios'
import formatMoney from '../../../../../services/utils/formatMoney'
import { isoToDateStr } from '../../../../../services/utils/iso_to_date_string'
import styles from '../../paymentsTable.module.css'

const Payment = ({payment, getData}) => {
    const months = [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
      ]

    const strFormatDate = () => {
        let result_date = payment.reference_date.split('T')[0]
        result_date = result_date.split('-')
        return months[parseInt(result_date[1])-1] + '/' + result_date[0]
    }
    
    const handleMarkAsPaid = async() => {
        if(payment.paid){
            const response = await markAsPaid(payment.id, false)
        }else{
            const response = await markAsPaid(payment.id, true)
        }
        await getData()
    }

    const deleteSelectedPayment = async() => {
        if (window.confirm('Cuidado! Tem certeza que deseja deletar esse pagamento?')){
            try {
                const response = await deletePayment(payment.id)
                if (response) {
                    await getData()
                }
            } catch (error) {
                Promise.reject(error)
                // showNotification({text: 'Erro ao deletar despesa.', type: 'error'})
            }
        } else {
            return
            // showNotification({text: 'Operação cancelada. Nada foi deletado.', type: 'warning'})
        }
    }
    
    return(
        <tr>
            <td>
                {payment.name}
            </td>
            <td>
                {formatMoney(payment.value)}
            </td>
            <td>
                {strFormatDate(payment.reference_date)}
            </td>
            <td className='payment_decription'>
                {payment.description?.trim()}
            </td>
            <td>
                {payment.paid?"Pago":"Pendente"}
            </td>
            <td>
                <button className={payment.paid?styles.paid_btn:styles.pending_btn} onClick={handleMarkAsPaid}>{'Alterar Status'}</button>
            </td>
            {payment.name &&
            <td>
                <button className={styles.pending_btn} onClick={deleteSelectedPayment}>{'Deletar'}</button>
            </td>
            }
            {/* {payment.name === "bônus" &&
            <td></td>
            } */}
        </tr>
    )
}

export default Payment