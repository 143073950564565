import { useContext, useState } from 'react'
import useScript from './useScript'
import { AuthContext } from '../../contexts/auth'
import './styles.css'
import Cookies from 'js-cookie'

export const clientId = process.env.REACT_APP_CLIENT_ID


export const LoginBtn = () => {
    const {login} = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)

    useScript('https://accounts.google.com/gsi/client')

    function decodeJwtResponse(token) {
        let base64Url = token.split('.')[1]
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload)
    }

    window.handleCredentialResponse = (response) => {
        const {name, picture, email} = decodeJwtResponse(response.credential)
        const user = {name, picture, email}
        Cookies.set('user', JSON.stringify(user) )
        setIsLoading(true)        
        login({credential: response.credential, user})
      }
    return(
        <>
        <div id="g_id_onload"
            data-client_id={clientId}
            data-context="signin"
            data-ux_mode="popup"
            data-callback="handleCredentialResponse"
            data-auto_prompt="false">
        </div>

        <div className="g_id_signin"
            data-type="standard"
            data-shape="pill"
            data-theme="filled_blue"
            data-text="signin_with."
            data-size="large"
            data-logo_alignment="left">
        </div>
        </>

    )
}
export const LogoutBtn = () => {

    const { logout } = useContext(AuthContext)

    const handleLogout = () => {
        logout()
    }

    return(
        <>
        <div className='logoutButton' onClick={handleLogout}>
            <span className='logoutButtonText'>Sair</span>
        </div>
        </>

    )
}