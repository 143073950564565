import { useContext, useEffect, useState } from 'react'
import './styles.css'
import {getAllUnities, exportFile, findQuarterByMonthAndYear, paymentsByDate, getUnityMetrics } from '../../services/axios'
import PageTitle from '../../components/pageTitle'
import MetricsTable from './table'
import { dateSlug, slugToTx } from '../../services/utils/dateSlug'
import Filter from './filter'
import { DatePickerContext } from '../../contexts/datePickerCtx'
import { isoToDateStr } from '../../services/utils/iso_to_date_string'
import { saveAs } from 'file-saver';


const Metricas = () => {
	const {period, formatCurrentDate, button_ref, handleSubmit, unity } = useContext(DatePickerContext)

	const [showUda,setShowUda] = useState(false)
    const [showUdf,setShowUdf] = useState(false)
    const [showEscritorio, setShowEscritorio] = useState(false)
    const [showTotal, setShowTotal] = useState(false)
	const [totalData, setTotalData] = useState()
	const [dataByUnityOriginal, setDataByUnityOriginal] = useState()
	const [dataByUnity, setDataByUnity] = useState()

	const changeAllStates = () => {
		switch(unity){
			case '1':
				setShowUda(true)

				setShowUdf(false)
				setShowEscritorio(false)
				setShowTotal(false)
				break
			case '2':
				setShowUdf(true)

				setShowUda(false)
				setShowEscritorio(false)
				setShowTotal(false)				
				break
			case '3':
				setShowEscritorio(true)

				setShowUda(false)
				setShowUdf(false)
				setShowTotal(false)				
				break
			case 'todas':
				setShowUda(true)
				setShowUdf(true)
				setShowEscritorio(true)
				setShowTotal(true)
				break
		}
	}

	useEffect(() => {
		changeAllStates()
	},[unity])

	const handleDownloadFile = async() => {
		window.print()
		// let by_quarter = false
		// let quarter_id = null
		// let by_month = false
		// let as_json = false
		// const {date, end_date, title} = formatCurrentDate()
		// const response = await exportFile({by_quarter, quarter_id, by_month, as_json, date, end_date})
		// if (response.data) {
		// 	const csvFile = new Blob([response.data], { type: 'text/csv' });
		// 	saveAs(csvFile, `relatorio ${title}.csv`);
		// }
	}

	const getMetrics = async () => {
		setTotalData()
		setDataByUnityOriginal()

		const {date, end_date, title} = formatCurrentDate()

		if(!date){
			return
		}

		let unitiesData = []
		let all_virtual_values = 0
		let all_real_values = 0
		let all_expenses = 0
		let all_pendency = 0
		let all_result = 0
		let all_urvs = 0

		const unities = await getAllUnities()

		const paymentsResponse = await paymentsByDate({
			by_month: period==='by_month',
			by_quarter: period==='by_quarter',
			isReverse: false,
			date,
			end_date
		})
		const payments = paymentsResponse.unities

		await Promise.all(
			unities.map(async (unity) => {
				const balance_response = await getUnityMetrics({
					unity_id: unity.id,
					by_month: period==='by_month',
					by_quarter: period==='by_quarter',
					isReverse: false,
					date,
					end_date
				})
				const {expenses, 
						real_values, 
						urv_value, 
						virtual_values, 
						total_urvs, collaborators, 
						virtual_total_distributed, 
						total_distributed} = balance_response
				const unity_payments = payments.find((item) => item.name===unity.name)
				const pendency = virtual_values-real_values
				const result = real_values-expenses
				if (unity.name !== 'Escritório'){
					all_virtual_values+=virtual_values
					all_real_values+=real_values
					all_result+=result
				}
				all_urvs+=total_urvs
				all_expenses+=expenses
				const info = {
					name: unity.name,
					urvs: total_urvs,
					expenses: expenses, 
					real: real_values, 
					virtual: virtual_values, 
					pendency: pendency<0?0:pendency, 
					result: result,
					title: `${unity.name} - ${title}`,
					unity_payments,
					virtual_total_distributed,
					collaborators,
					distributed: total_distributed,
					urv_price: urv_value,
				}
				unitiesData.push(info)
			})
		)
	
				const total_todo_escritorio = {
					name: 'Total de todas unidades',
					virtual: all_virtual_values,
					real: all_real_values,
					expenses: all_expenses,
					pendency: all_pendency<0?0:all_pendency,
					result: all_result,
					urvs: all_urvs,
					title: `Total - ${title}`
				}
				setTotalData(total_todo_escritorio)
				setDataByUnity(unitiesData)
				setDataByUnityOriginal(unitiesData)
	}

	useEffect(()=> {
		setTotalData();
		setDataByUnity()
        button_ref.current.onclick = () => handleSubmit(getMetrics)
		setTimeout(() => {
			getMetrics()
		}, 300);
	},[period, unity])

	return (

		<div id='metricas' className='pageContainer'>
			<PageTitle title={'Relatórios'} />
			<div className='valuesContainer'>
					<section className='section'>
						<div className='row'>
							<h2 className='sectionTitle'>
								Métricas do {period==='by_day'?'Dia':period==='by_month'?'Mês':period==='by_quarter'?'Trimestre':period==='by_year'?'Ano':period==='by_range'?'Intervalo':''}
							</h2>
							<span onClick={handleDownloadFile} className='downloadFile'>
								Download
								<span className='material-symbols-outlined'>download</span>
							</span>
						</div>
						<div className='topLine'>
							<Filter showUda={showUda} showUdf={showUdf} showEscritorio={showEscritorio} showTotal={showTotal} 
							setShowUda={setShowUda} 
							setShowUdf={setShowUdf} 
							setShowEscritorio={setShowEscritorio}
							setShowTotal={setShowTotal} />
						</div>
						<div>
						</div>
							{totalData && dataByUnity?(
								<>
								{showTotal?(
									<MetricsTable values={totalData} />	
								):''}
									{dataByUnity?.map((unity, index) => (<MetricsTable unitySelector={unity} key={`${unity.id}${index}`} showUda={showUda} showUdf={showUdf} showEscritorio={showEscritorio} isUnity period={period} values={unity} />))}
								</>
								
							):(
								<table id='metricsTable' className=''>							
									<tbody>
										<tr >
											<th  className='fullWidthRow'>Escolha um Intervalo </th>
										</tr>
									</tbody>
								</table>
							)}
					</section>
			</div>
		</div>
	)
}
export default Metricas
