import { useEffect, useRef, useState } from "react"
import { today } from '../../../components/today';
import { createRealValue, createVirtualValue, getAllCustomers, getAllUnities, getCollaborators, getTags, getUnity } from "../../../services/axios.js";

import './styles.css'
import Button from "../../../components/button";
import { MoneyInput } from "../../../components/moneyInput";
import formatMoney, { unformatMoney } from "../../../services/utils/formatMoney";
import { useNotification } from "../../../components/useNotification";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Stack,
  Chip
} from "@mui/material";

export const stallmentsOptions = [
    {name: 'À vista', id: 0},
    {name: '2', id: 2},
    {name: '3', id: 3},
    {name: '4', id: 4},
    {name: '5', id: 5},
    {name: '6', id: 6},
    {name: '7', id: 7},
    {name: '8', id: 8},
    {name: '9', id: 9},
    {name: '10', id: 10},
    {name: '11', id: 11},
    {name: '12', id: 12},
]
 const ValuesForm = ({getVValues, defaultClient = null, allTags = []}) => {
    const {Notification, showNotification } = useNotification()

    const blankForm ={payer_id: '', value: '', description: '', stallments: '', payment_date: today, paid: false, unity_id: '', collaborator_id: ''}
    const payer_id_ref = useRef()
    const description_ref = useRef()
    const payment_date_ref = useRef()
    const reference_date_ref = useRef()
    const unity_id_ref = useRef()
    const collaborator_id_ref = useRef()
    const [moneyValue, setMoneyValue] = useState('')
    const [realMoney, setRealMoney] = useState('')
    const [paid_out, setPaid_out] = useState(false)
    const stallmentsRef = useRef()


    const [customers, setCustomers] = useState([])
    const [collabs, setCollabs] = useState([])
    const [unities, setUnities] = useState ([])

    const [selectedTags, setSelectedTags] = useState([]);

    // const tags = [
    //     {name:"da1", color: 'rgb(230, 106, 169)'},
    //     {name:"da2", color:'rgb(230, 106, 169)'},
    //     {name:"da3", color:'rgb(230, 106, 169)'},
    //     {name:"da4", color:'rgb(230, 106, 169)'},
    //     {name:"da5", color:'rgb(230, 106, 169)'},
    //     {name:"udf", color:'rgb(230, 106, 169)'},
    //     {name:"nit", color:'rgb(230, 106, 169)'},
    //     {name:"px", color:'rgb(230, 106, 169)'},
    //     {name:"Êxito", color:'rgb(0, 255, 0)'},
    // ];

    const updateSelectedTags = (e) => {
        let tagsToAdd = []
        let tagsAdded = {}
        e.forEach((tag) => {
            if (tagsAdded[tag.name])
                tagsAdded[tag.name] += 1
            else
                tagsAdded[tag.name] = 1
        })
        console.log(tagsAdded)
        e.forEach((tag) => {
            if (tagsAdded[tag.name] == 1)
                tagsToAdd.push(tag)
        })
        setSelectedTags(tagsToAdd)
    }
    
    const getData = async() => {
        const customerData = await getAllCustomers('name',false)
        setCustomers(customerData)
        const collabData = await getCollaborators(0)
        setCollabs(collabData)
        const unitiesData = await getAllUnities()
        setUnities(unitiesData)
    }

    useEffect(() => {
        getData()
    },[])
    useEffect(() => {
        setTimeout(() => {
            if(defaultClient){
                payer_id_ref.current.value = defaultClient.id
            }
        }, 500);
    },[customers])
    
    const handleSubmitValues = async (e) => {
        const values = blankForm
        const realMoney_number = unformatMoney(realMoney)
        e.preventDefault()
        document.querySelector('.btnCadastrar').disabled = true
        values.payer_id = payer_id_ref.current.value
        values.unity_id = unity_id_ref.current.value
        values.reference_date = new Date(reference_date_ref.current.value.split('-'))
        values.payment_date = new Date(payment_date_ref.current.value.split('-'))
        values.value = unformatMoney(moneyValue)
        values.description = description_ref?.current?.value
        values.collaborator_id = collaborator_id_ref.current.value != '-1' ? collaborator_id_ref.current.value : null
        values.stallments = parseInt(stallmentsRef.current.value)
        
            if (paid_out){
                if (values.value*0.3 >= realMoney_number){
                    if (!window.confirm(`O valor digitado ${formatMoney(realMoney_number/100)} é muito menor que o Virtual ${formatMoney(values.value/100)}. Tem certeza que está correto e deseja prosseguir?`)){
                        showNotification({text:'Operação cancelada.', type: 'warning'}) 
                     return
                     }
                 }
                 else if (values.value<realMoney_number){
                    if (!window.confirm(`O valor digitado ${formatMoney(realMoney_number/100)} é maior que o Virtual ${formatMoney(values.value/100)}. Tem certeza que está correto e deseja prosseguir?`)){
                        showNotification({text:'Operação cancelada.', type: 'warning'}) 
                     return
                     }
                 }
                const createdValue = await createVirtualValue(values)
                const newRealvalue = {
                    quarter_id: createdValue.data.quarter_id, 
                    payer_id: createdValue.data.payer_id,
                    unity_id: createdValue.data.unity_id, 
                    collaborator_id: createdValue.data.collaborator_id, 
                    vvalue_id: createdValue.data.id,
                    value: realMoney_number,
                }
                const response = await createRealValue(newRealvalue)
                if (response.status === 200) {
                    resetAndCall()
                }
            }else{
                const createdValue = await createVirtualValue(values)
                if (createdValue.status === 200) {
                    resetAndCall()
                }
            }
            document.querySelector('.btnCadastrar').disabled = false
    }

    const resetAndCall = () => {
        showNotification({text:'Valor registrado', type: 'ok'}) 
        getVValues()
    }

    return (
 <form id='valuesForm' className='containerCadastrar'>
    {Notification}
                <h2>Nova receita: </h2> <br/>
                <div className='allFieldsCadastrar'>
                    <div className="field">
                        <label>Cliente: </label>
                        <select className='select' 
                        defaultValue='-1'
                        ref={payer_id_ref}
                          name="clientSelect" id="clientSelect">
                                <option value='-1' disabled hidden>Escolha o Cliente</option>
                                {customers?.map((customer,index) => 
                                <option key={index} value={customer.id}>{customer.name}</option>
                                )}
                        </select>
                    </div>
                    <div className="field">
                        <label>Colaborador: </label>
                        <select className='select'
                        defaultValue='-1'
                        ref={collaborator_id_ref}
                        name="collabSelect" id="collabSelect">
                                <option value='-1'  disabled hidden>Colaborador responsável</option>
                                {collabs?.map((collab,index) => 
                                <option key={index} value={collab.id}>{collab.name}</option>
                                )}
                        </select>
                    </div>
                    <div className="field">
                        <label>Unidade: </label>
                        <select className='select'
                        defaultValue='-1'
                       ref={unity_id_ref}
                        name="collabSelect" id="collabSelect">
                                <option value='-1' disabled hidden>Setor responsável</option>
                                {unities?.map((unity,index) => 
                                <option key={index} value={unity.id}>{unity.name}</option>
                                )}
                        </select>
                    </div>
                    <div className="field">
                        <label>Valor: </label>
                        <MoneyInput value={moneyValue} setValue={setMoneyValue}/>
                    </div>
                    <div className="field">
                        <label>Observações: </label>
                        <textarea required type='text' className='input'
                        ref={description_ref}
                        placeholder='Observações'></textarea>
                    </div>
                    <div className="field">
                        <label>Parcelas: </label>
                        <select ref={stallmentsRef} required className='input stallments'
                        >
                            {stallmentsOptions.map((item, index) => (
                                <option key={index} value={item.id}>{item.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="field">
                        <label>Data de referência: </label>
                        <input required type='date' className='dateField' 
                        ref={reference_date_ref}
                        ></input>
                    </div>
                    <div className="field">
                        <label>Dia do pagamento: </label>
                        <input required type='date' className='dateField' 
                        ref={payment_date_ref}
                        ></input>
                    </div>
                    <div className="field">
                        <label>Já foi pago?</label>
                        <input type='checkbox' className='dateField' 
                        checked={paid_out} onChange={() => {
                            setPaid_out( prev => !prev)
                        }} ></input>
                    </div>
                    {paid_out?(
                    <div className="field">
                    <label>Valor pago: </label>
                        <MoneyInput value={realMoney} setValue={setRealMoney}/>
                    </div>):''}
                    <div>
                    <InputLabel className="field" style={{color: 'white'}}>Marcadores</InputLabel>
                    <Select
                        multiple
                        style={{minWidth: '80px', maxWidth: '200px', border: 'white'}}
                        value={selectedTags}
                        onChange={(e) => updateSelectedTags(e.target.value)}
                        input={<OutlinedInput label="Multiple Select" />}
                        renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                            {selected.map((value) => (
                            <Chip key={value.name} label={value.name} style={{backgroundColor: value.color}}/>
                            ))}
                        </Stack>
                        )}
                    >
                        {allTags?.map((tag) => (
                        <MenuItem key={tag.name} value={tag}>
                            {tag.name}
                        </MenuItem>
                        ))}
                    </Select>
                    </div>
                    <Button externo={true} classname="btnCadastrar" title='Cadastrar nova receita (virtual)' onclick={handleSubmitValues}/>
                </div>
            </form>
    )
}
export default ValuesForm