export function isoToDateStr(isoDate) {
    if (!isoDate) {
        return ''
    }
    return isoDate
        ? isoDate.slice(0, 10).split('-').reverse().join('/')
        : null
}

export function isoToDefaultInputValue(isoDate) {
    if (!isoDate) {
        return
    }
    return isoDate.slice(0, 10)
}