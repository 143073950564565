import Collab from './collaborator'
import styles from './paymentsTable.module.css'

const PaymentTable = ({paymentByUnity, getData}) => {
    let hasPayments = false
    paymentByUnity.collaborators.forEach((collab) => {
        if (collab.payments.length > 0){
            hasPayments = true
        }
    })
    if (hasPayments) {

        return (
            <>
                <h3>{paymentByUnity.name}</h3>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>TIPO</th>
                            <th>VALOR</th>
                            <th>DATA</th>
                            <th>DESCRIÇÃO</th>
                            <th>STATUS</th>
                            <th>ALTERAR STATUS</th>
                            <th></th>
                        </tr>
                    </thead>
                    {paymentByUnity?.collaborators?.map((collab) => (
                            <Collab collab={collab} getData={getData} />
                            ))}
                </table>
            </>
        )
    }
}

export default PaymentTable