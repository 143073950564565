import { useRef, useState } from 'react';
import Modal from 'react-modal';
import { createNewProgression, deleteCollaborator, updateCollaborator } from '../../../services/axios';
import './styles.css'
import Button from '../../../components/button';
import { MoneyInput, formatMoney } from '../../../components/moneyInput';
import { today } from '../../../components/today';
import { useNotification } from '../../../components/useNotification'
import { formatToMoney } from '../../../services/utils/formatMoney';
const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: 600,
      height: 500,
      transform: 'translate(-50%, -50%)',
      backgroundColor: '#1d3562',
      color: 'White'
    },
  };

const ColabModal = ({getCollabs, colab, index, unities, modalIsOpen, setIsOpen}) => {
  const { Notification, showNotification } = useNotification()

  const modifyDateRef = useRef()
  const nameRef = useRef()
  const emailRef = useRef()
  const [money, setMoney] = useState(formatToMoney(colab.progression.basic_rem))
  const urvsRef = useRef()
  const unityRef = useRef(colab.unity_id)
    const progressionRef = useRef()

    // const [modalIsOpen, setIsOpen] = useState(true);
    const [showDeleteForm, setShowDeleteForm] = useState (false)
    const [showEditForm, setShowEditForm] = useState (false)

    const deleteRef = useRef()
    const editRef = useRef()
    const toggleDeleteForm = (e) => {
      e.preventDefault()
      setShowEditForm(false)
      setShowDeleteForm((prev) => !prev)
    }

    const toggleEditForm = (e) => {
      e.preventDefault()
      setShowDeleteForm(false)
      const inputs = document.querySelectorAll('.editableInput')
      setShowEditForm((prev)=> !prev)
      inputs.forEach((input) => input.removeAttribute('disabled'))
    }

    const editUser = async (e) => {
      e.preventDefault()
      // const modifyDate = new Date(modifyDateRef.current.value.split('-').join('/')) 

      if(editRef.current.value.toUpperCase() === 'EDITAR'){
        try {
          const modified_user = {
            id: colab.id,
            email: emailRef.current.value,
            name: nameRef.current.value,
            unity_id: unityRef.current.value
          }

          // const newProgression = {
          //   date: modifyDate,
          //   basic_rem: money,
          //   urvs: urvsRef.current.value,
          //   unity_id: unityRef.current.value,
          //   collaborator_id: colab.id
          // }
          
         const responseColab= await updateCollaborator(modified_user)
        //  const responseProgression = await createNewProgression(newProgression)
         getCollabs()
         showNotification({text: 'Colaborador Editado!', type: 'ok'})
        return
        } catch (error) {
          Promise.reject(error)
          showNotification({text: 'Erro ao editar colaborador!', type: 'error'})
          console.log(error)
        }
      }else{
        showNotification({text: 'Palavra digitada não confere.', type: 'warning'})
        return
      }
    }

    const deleteUser = async(e) => {
      e.preventDefault()

      if(deleteRef.current.value.toUpperCase() === 'DELETAR'){
       try {
         await deleteCollaborator(colab.id)
         getCollabs()
         showNotification({text: 'Colaborador removido!', type: 'ok'})
       } catch (error) {
        console.log("🚀 ~ error:", error)
        showNotification({text: 'Erro ao tentar deletar colaborador.', type: 'error'})
       }
      }else{
        showNotification({text: 'Palavra digitada não confere.', type: 'warning'})
      }
    }

  async function openModal() {
      setIsOpen(true);
    }
    
    function closeModal() {
      setShowDeleteForm(false)
      setShowEditForm(false)
      setIsOpen(false);
    }
    return(
      <>
      {Notification}
        <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Collaborator"
        >
          <form id='modal'>
            <div className='modalField'>
              <label className='label'>Nome: </label>
              <input ref={nameRef} className='editableInput' name='name' type='text' defaultValue={colab.name}
              />
            </div>
            <div className='modalField'>
              <label className='label'>Email: </label>
              <input ref={emailRef} className='editableInput' 
              name='email' type='email' 
              defaultValue={colab.email}
              />
            </div>
            <div className='modalField'>
              <label className='label'>Remuneração: </label>
              <MoneyInput value={money} disabled setValue={setMoney}/>
            </div>
            <div className='modalField'>
              <label className='label'>Urvs: </label>
              <input ref={urvsRef} className='-editableInput' name='urvs' type='number' disabled defaultValue={colab.progression.urvs}
              />
            </div>
            <div className='modalField'>
              <label className='label'>Equipe:  </label>
              <select disabled ref={unityRef} className='-editableInput' defaultValue={colab.unity_id} >
                  {unities?.map((unity, index) => 
                    <option key={index} value={unity.id}>{unity.name}</option>
                  )}
              </select>
            </div>
            <div className='modalField'>
              <label className='label'>Nível de progressão na carreira: </label>
              <input className='-editableInput' name='progression_stage' type='number' disabled 
              defaultValue={colab.progression.progression_stage}
              ref={progressionRef}
              />
            </div>
            <div className='modifyBtnsContainer'>
              <Button externo={true} classname='modifyBtns' onclick={toggleEditForm} title='Editar Colaborador'/>
              <Button externo={true} onclick={toggleDeleteForm} classname='modifyBtns'title='Deletar Colaborador'/>
            </div>
            {showEditForm?(
            <div className=''>
              {/* <div className='deleteLabel'>
                <label>Data da alteração: </label>
                <input ref={modifyDateRef} type='date' defaultValue={today} />
              </div> */}
              <label className='deleteLabel'>Deseja realmente <span className='highlight'>EDITAR</span> o Colaborador atual no banco de dados?</label><br/>
              <label className='deleteLabel'>Digite <span className='highlight'>EDITAR</span> para prosseguir: </label>
              <input type='text' ref={editRef}></input>
              <Button externo={true} onclick={editUser} title='Confirmar' classname='submitBtn'/>
            </div>
            )
            :''
          }
            {showDeleteForm?(
            <div className=''>
              <label className='deleteLabel'>Deseja realmente <span className='highlight'>DELETAR</span> o Colaborador atual do banco de dados?</label><br/>
              <label className='deleteLabel'>Um colaborador só deve ser deletado se seu cadastro for <span className='highlight'>INDEVIDO</span>!</label><br/>
              <label className='deleteLabel'>Se o colaborador tiver saído do escritório, apenas finalize sua última progressão</label><br/>
              <label className='deleteLabel'>Digite <span className='highlight'>DELETAR</span> para prosseguir: </label>
              <input type='text' ref={deleteRef}></input>
              <Button externo={true} classname='submitBtn' onclick={deleteUser} title='Confirmar'/>
            </div>
            )
            :''
          }
          <div className='closeModalBtn' onClick={closeModal}>Voltar</div>
          </form>
      </Modal>
        </>
    )
}

export default ColabModal