import './styles.css'

const Filter = ({isTotalVisible=true, showUda, showUdf, showEscritorio, showTotal, setShowUda, setShowUdf, setShowEscritorio, setShowTotal}) => {
    const toggleUda = () => {
        setShowUda(prev=>!prev)
    }
    const toggleUdf = () => {
        setShowUdf(prev=>!prev)
    }
    const toggleEscritorio = () => {
        setShowEscritorio(prev=>!prev)
    }
    const toggleTotal = () => {
        setShowTotal(prev=>!prev)
    }

    return(
        <div id="filter">
            <div className='field'>
                <label htmlFor='uda'>UDA</label>
                <input id='uda' onChange={toggleUda} checked={showUda} type='checkbox' />
            </div>
            <div className='field'>
                <label htmlFor='udf'>UDF</label>
                <input id='udf' onChange={toggleUdf} checked={showUdf} type='checkbox' />
            </div>
            <div className='field'>
                <label htmlFor='escritorio'>Escritório</label>
                <input id='escritorio' onChange={toggleEscritorio} checked={showEscritorio} type='checkbox' />
            </div>
            {isTotalVisible?(
                <div className='field'>
                    <label htmlFor='total'>Total</label>
                    <input id='total' onChange={toggleTotal} checked={showTotal} type='checkbox' />
                </div>
            ):''}

        </div>
    )
}

export default Filter